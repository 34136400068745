import React from 'react';
import {Modal, Button} from 'react-bootstrap';
import Notifications from 'react-notify-toast';

import {fraisCrossOverPays, modesPaiementMobile, operateurCI, operateurCM, operateurGN, operateurKN, operateurMALI, operateurXAF, operateurXOF, operateurGNF, operateurKES, operateurWithAirtime, operateurOnlyAirtime, fraisCrossOverDevise, operateurAfrique, operateurAmerique, operateurEurope, operateurAsie, operateurOceanie, operateurAntartique, fraisCrossOverContinant, getPourcentageMomoVersMomo, isSameCurrencyMomoVersMomo, isSameCountryMomoVersMomo, getTauxConversionMomoVersMomo, getCurrencyMomoVersMomo} from '../config/globales';

import {Data} from '../services/data-manager';
import {notifyDanger, notifySuccess} from '../utils';


class MobileVersMobile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mvm: {
				numeroSource: "",
				numeroDestination: "",
				operateurSource: modesPaiementMobile[0].operateur,
				operateurDestination: modesPaiementMobile[0].operateur,
                montant: 0,
                deviseSource: modesPaiementMobile[0].devise,
                deviseDestination: modesPaiementMobile[0].devise,
                isAirtime: false
			},
            yup: {
              code: "",
              show: false
            },
            statusShow: false,
            confirmShow: false
        };
        this.momoVersMomo = this.momoVersMomo.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.getStatus = this.getStatus.bind(this);
        this.statusModalShow = this.statusModalShow.bind(this);
        this.yupModalShow = this.yupModalShow.bind(this);
        this.confirmationYUP = this.confirmationYUP.bind(this);
    }

    handleInputChange(event, isYUP=false) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        if (isYUP) {
            let yup = this.state.yup;
            yup[name] = value;
            this.setState({yup: yup});
        } else {
            let mvm = this.state.mvm;
            mvm[name] = value;
            if (name=="operateurSource") {
                for (let i = 0; i < modesPaiementMobile.length; i++) {
                    if (modesPaiementMobile[i].name==value) {
                        mvm.deviseSource = modesPaiementMobile[i];
                        break;
                    }
                }
            }
            if (name=="operateurDestination") {
                for (let i = 0; i < modesPaiementMobile.length; i++) {
                    if (modesPaiementMobile[i].name==value) {
                        mvm.deviseDestination = modesPaiementMobile[i];
                        break;
                    }
                }
            }
            this.setState({mvm: mvm});
        }
    }

    momoVersMomo(additional=null, isConfirmed=false) {
        let mvm = this.state.mvm;
        if (additional!==null) {
            mvm.additional = additional;
            this.setState({mvm:mvm});
        }
        if (this.state.mvm.montant<500) {
            notifyDanger("Le montant minimal est 500");
            document.querySelector("#mvm_montant").focus();
            return false;
        }
        if (this.state.mvm.montant>500000) {
            notifyDanger("Le montant maximal est 500 000");
            document.querySelector("#mvm_montant").focus();
            return false;
        }
        // Confirmation de la destination
        if (!isConfirmed) {
            this.confirmModalShow(true);
            return false;
        }
        // Code de sécurité YUP
        if (this.state.mvm.operateurSource==="YUP" && additional===null) {
            new Data().send("/get-yup-code", {
                montant: this.state.mvm.montant,
                numero: this.state.mvm.numeroSource
            });
            this.yupModalShow(true);
            return false;
        }
        //*/ Transaction
        this.confirmModalShow(false);
        mvm.montantDestination = (isSameCountryMomoVersMomo(this.state.mvm.operateurSource, this.state.mvm.operateurDestination))?this.state.mvm.montant:Math.ceil((1-getTauxConversionMomoVersMomo(this.state.mvm.operateurSource, this.state.mvm.operateurDestination)/100)*this.state.mvm.montant*getTauxConversionMomoVersMomo(this.state.mvm.operateurSource, this.state.mvm.operateurDestination));
        new Data().send("/momo-vers-momo", mvm)
        .then(res => {
            console.log("Paiement: ",res);
            if (res.status) {
                if (res.status!=="FAILED") {
                    this.statusModalShow(true);
                    if (this.state.mvm.operateurSource==="YUP" && additional!==null) {
                        res.isYUP = 1;
                        res.numero = this.state.mvm.numeroSource;
                    }
                    this.getStatus(res);
                }
            }
        })
        .catch(err => {
            alert("La transaction à échoué. Veuillez reéssayer.");
            console.warn("Error : ", err);
        });//*/
        
        // Fin de la transaction
        if (additional!==null) {
            let mvm = this.state.mvm;
            mvm.additional = null;
            this.setState({mvm:mvm});
        }
        return false;
    }

    yupModalShow(show) {
      this.setState({yup: {show: show}});
    }
  
    confirmModalShow(show) {
      this.setState({confirmShow: show});
    }
  
    statusModalShow(show) {
      this.setState({statusShow: show});
    }
  
    confirmationYUP() {
        if (this.state.yup.code==="") {
            notifyDanger("Veuillez entrer le code de confirmation YUP.");
            return false;
        }
        this.yupModalShow(false);
        this.momoVersMomo({
            isYUP: true,
            code: this.state.yup.code
        }, true);
    }

    getStatus(transaction) {
        var addQString = "";
        if (transaction.isYUP) addQString = "&isYUP=1&numero=" + transaction.numero;
        new Data().send("/payement-mobile-status?idTransaction="+transaction.id+addQString)
        .then(res => {
            if (res.status) {
                if (res.status==="PENDING") {
                    setTimeout(this.getStatus, 5000, transaction);
                } else {
                    this.statusModalShow(false);
                    if (res.status!=="FAILED") {
                        notifySuccess(<strong>Transaction effectué avec succès.<br />Le bénéficiaire à déjà reçu le montant du transfert.</strong>);
                    } else {
                        notifyDanger("Le paiement à échoué !");
                    }
                }
            } else {
                setTimeout(this.getStatus, 5000, transaction);
            }
        })
        .catch(err => {
            notifySuccess("Transaction effectué avec succès.<br>Le bénéficiaire à déjà reçu le montant du transfert.");
            console.warn("Error : ", err);
            setTimeout(this.getStatus, 10000, transaction.idTransaction);
        })
    }

    render() {
        return (
            <>
                <Notifications options={{top:'40vh'}} />
                <section id="mobile-vers-mobile" className="alert-secondary py-3">
                    <div className="container-fluid">
                        <form action="/mobile-vers-mobile" method="post" className="col-md-12 contactForm" onSubmit={(e) => {e.preventDefault(); this.momoVersMomo()}}>
                            <div className="card card-body text-dark">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h1>Transfert de mobile vers mobile</h1>
                                        <hr className="border-secondary" />
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="operateurSource">Mobile source</label>
                                            <select name="operateurSource" id="operateurSource" value={this.state.mvm.operateurSource} className="form-control border-secondary" onChange={(e) => {this.handleInputChange(e)}}>
                                                {( modesPaiementMobile.map(m => (!m.forAirtime)?<option value={ m.operateur } key={'mode-source-'+m.operateur}>{ m.name }</option>:'') )}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="numeroSource">Numéro de l'expéditeur</label>
                                            <input type="text" name="numeroSource" id="numeroSource" value={this.state.mvm.numeroSource} className="form-control border-secondary" placeholder="Numéro de téléphone" onChange={(e) => {this.handleInputChange(e)}} />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="operateurDestination">Mobile de destination</label>
                                            <select name="operateurDestination" id="operateurDestination" value={this.state.mvm.operateurDestination} className="form-control border-secondary" onChange={(e) => {this.handleInputChange(e)}}>
                                                {( modesPaiementMobile.map(m => <option value={ m.operateur } key={'mode-destination-'+m.operateur}>{ m.name }</option>) )}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="numeroDestination">Numéro du bénéficiaire</label>
                                            <input type="text" name="numeroDestination" id="numeroDestination" value={this.state.mvm.numeroDestination} className="form-control border-secondary" placeholder="Numéro du destinataire" onChange={(e) => {this.handleInputChange(e)}} />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="mvm_montant">Montant</label>
                                            <input type="text" name="montant" value={this.state.mvm.montant} id="mvm_montant" className="form-control border-secondary" onChange={(e) => {this.handleInputChange(e)}} />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>Voulez vous plutôt le crédit de communication ?</label>
                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" id="mvm_isAirtime" name="isAirtime" checked={this.state.mvm.isAirtime} onChange={(e) => {this.handleInputChange(e)}} />
                                                <label className="custom-control-label" htmlFor="mvm_isAirtime">Je veux que le destinataire reçoive plutôt le crédit de communication</label>
                                            </div>
                                        </div>
                                    </div>
                                    {(this.state.mvm.montant>=500 && !isSameCountryMomoVersMomo(this.state.mvm.operateurSource, this.state.mvm.operateurDestination))?
                                    <div className="col-sm-12">
                                        <div className="alert alert-warning">
                                            Les frais de transaction cross-over seront appliqués au taux de &nbsp; 
                                            <strong>
                                            {getPourcentageMomoVersMomo(this.state.mvm.operateurSource, this.state.mvm.operateurDestination)}% &nbsp; 
                                            </strong>
                                            au montant de &nbsp; 
                                            <strong>
                                            {Intl.NumberFormat().format(Math.ceil((getPourcentageMomoVersMomo(this.state.mvm.operateurSource, this.state.mvm.operateurDestination)/100)*this.state.mvm.montant))} {getCurrencyMomoVersMomo(this.state.mvm.operateurSource)}
                                            </strong>.
                                            {(!isSameCurrencyMomoVersMomo(this.state.mvm.operateurSource, this.state.mvm.operateurDestination))?
                                            <>
                                            <br />
                                            Le destinataire va recevoir &nbsp; 
                                            <strong>
                                            {Intl.NumberFormat().format(Math.ceil((1-getTauxConversionMomoVersMomo(this.state.mvm.operateurSource, this.state.mvm.operateurDestination)/100)*this.state.mvm.montant))} {getCurrencyMomoVersMomo(this.state.mvm.operateurSource)}
                                            &nbsp; = &nbsp;
                                            {Intl.NumberFormat().format(Math.ceil((1-getTauxConversionMomoVersMomo(this.state.mvm.operateurSource, this.state.mvm.operateurDestination)/100)*this.state.mvm.montant*getTauxConversionMomoVersMomo(this.state.mvm.operateurSource, this.state.mvm.operateurDestination)))} {getCurrencyMomoVersMomo(this.state.mvm.operateurDestination)}
                                            .
                                            </strong>
                                            </>:''
                                            }
                                        </div>
                                    </div>:''
                                    }
                                    {(this.state.mvm.montant>=500 && this.state.mvm.isAirtime && operateurWithAirtime.indexOf(this.state.mvm.operateurDestination)===-1)?
                                    <div className="col-sm-12">
                                        <div className="alert alert-danger">
                                            Le transfert de crédit n'est valable que pour les opérateurs suivants : MTN, ORANGE, MOOV, TIGO, MALITEL, AIRTEL et SAFARICOM.
                                            <br />
                                            Veuillez choisir un opérateur de destination valide ou décocher l'option de crédit de communication.
                                        </div>
                                    </div>:''
                                    }
                                    {(this.state.mvm.montant>=500 && !this.state.mvm.isAirtime && operateurOnlyAirtime.indexOf(this.state.mvm.operateurDestination)>=0)?
                                    <div className="col-sm-12">
                                        <div className="alert alert-danger">
                                            L'opérateur de destination choisir n'est valable que pour les transfert de crédit de communication. Si tel est votre besoin, veuillez cocher l'option crédit de communication.
                                        </div>
                                    </div>:''
                                    }
                                    {(this.state.mvm.montan!=0 && (this.state.mvm.montant<500 || this.state.mvm.montant>((operateurCI.indexOf(this.state.mvm.operateurDestination)>=0 && operateurCI.indexOf(this.state.mvm.operateurSource)>=0)?1000000:500000)))?
                                    <div className="col-sm-12">
                                        <div className="alert alert-danger">
                                            Le montant de la transaction doit être compris entre <strong>500</strong> et <strong>{(operateurCI.indexOf(this.state.mvm.operateurDestination)>=0 && operateurCI.indexOf(this.state.mvm.operateurSource)>=0)?"1 000 000":"500 000"}</strong>.
                                        </div>
                                    </div>:''
                                    }
                                    <div className="col-sm-12 mt-3">
                                        <button className="btn btn-success btn-block btn-lg" type="submit">Transférer</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
                <Modal className="mt-5" show={this.state.yup.show} onHide={() => {this.yupModalShow(false)}}>
                    <Modal.Header closeButton>
                        <Modal.Title>Code YUP</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <label id="code">Code YUP</label>
                            <input type="number" name="code" id="code" value={this.state.yup.code} className="form-control border-secondary" placeholder="Entrer le code de sécurité YUP" onChange={(e) => {this.handleInputChange(e, true)}} />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {this.yupModalShow(false)}}>
                            Close
                        </Button>
                        <Button variant="success" onClick={this.confirmationYUP}>
                            Confirmer
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal className="mt-5" show={this.state.statusShow} onHide={() => {this.statusModalShow(false)}}>
                    <Modal.Header closeButton>
                        <Modal.Title>Paiement encours</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h3 className="py-5">En attente de validation...</h3>
                    </Modal.Body>
                    <Modal.Footer></Modal.Footer>
                </Modal>
                <Modal className="mt-5" show={this.state.confirmShow} onHide={() => {this.confirmModalShow(false)}}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmation du bénéficiaire</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Êtes vous sûr de vouloir transférer
                        &nbsp;
                        <strong>
                            {Intl.NumberFormat().format((isSameCountryMomoVersMomo(this.state.mvm.operateurSource, this.state.mvm.operateurDestination))?this.state.mvm.montant:Math.ceil((1-getTauxConversionMomoVersMomo(this.state.mvm.operateurSource, this.state.mvm.operateurDestination)/100)*this.state.mvm.montant*getTauxConversionMomoVersMomo(this.state.mvm.operateurSource, this.state.mvm.operateurDestination)))} {getCurrencyMomoVersMomo(this.state.mvm.operateurDestination)}
                        </strong>
                        {(this.state.mvm.isAirtime)?<strong> &nbsp; de crédit de communication &nbsp; </strong>:''}
                        &nbsp;
                        au numéro
                        &nbsp;
                        <strong>
                            {this.state.mvm.numeroDestination}
                        </strong>
                        &nbsp; ?
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-success" onClick={() => {this.momoVersMomo(null, true);}} type="button">Confirmer</button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default MobileVersMobile;