import {notify} from 'react-notify-toast';

function notifySuccess(message) {
    notify.show(
        message, 
        "success", 
        30000, 
        {
            background: "#20D67B",
            text: "#2daf1f"
        }
    );
}

function notifyDanger(message) {
    notify.show(
        message, 
        "error", 
        30000, 
        {
            background: "#D44950",
            text: "#fff"
        }
    );
}

function getUUID(){
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (dt + Math.random()*16)%16 | 0;
        dt = Math.floor(dt/16);
        return (c==='x' ? r :(r&0x3|0x8)).toString(16);
    });
    return uuid;
}

export {notifyDanger, notifySuccess, getUUID};