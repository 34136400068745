import moment from 'moment';
import {Data} from './services/data-manager';

export default class Auth {
    utilisateur = null;
    isAuth = false;
    constructor() {
        this.getUtilisateur();
        this.deconnexion = this.deconnexion.bind(this);
        this.isAuthenticated = this.isAuthenticated.bind(this);
    }
    login(login, password, remember=false) {
        return new Data()
        .getOne("Utilisateur", `(email="${login}" OR telephone="${login}") AND pass=MD5("${password}")`, "marchands")
        .then(res => {
            console.log(res);
            if (res) {
                this.utilisateur = res;
                this.storeUtilisateur(this.utilisateur);
                this.isAuth = true;
                // Expiration
                let date = moment();
                let dateExpiration = date.add(30,"minutes");
                localStorage.setItem("dateCreation", date.format("YYYY-MM-DD HH:mm:ss"));
                localStorage.setItem("dateExpiration", (remember)?0:dateExpiration.format("YYYY-MM-DD HH:mm:ss"));
                window.location.reload();
                return true;
            } else {
                return false;
            }
        })
    }
    deconnexion() {
        localStorage.clear();
        this.isAuth = false;
        this.utilisateur = null;
        window.location.reload();
    }
    storeUtilisateur(utilisateur) {
        localStorage.setItem("utilisateur", JSON.stringify(utilisateur));
    }
    isAuthenticated() {
        this.isExpired();
        return this.isAuth;
    }
    getUtilisateur() {
        this.isExpired();
        if (!this.utilisateur) {
            this.utilisateur = JSON.parse(localStorage.getItem("utilisateur"));
        }
        this.isAuth = this.utilisateur!==null;
        return this.utilisateur;
    }
    isExpired() {
        let result = false;
        let dateExpiration = localStorage.getItem("dateExpiration");
        if (dateExpiration===null) result = true;
        else if (dateExpiration==0) result = false;
        else {
            dateExpiration = moment(dateExpiration, "YYYY-MM-DD HH:mm:ss");
            let date = moment();
            if (dateExpiration.diff(date,"s")<0) {
                localStorage.clear();
                this.utilisateur = null;
                this.isAuth = false;
                result = true;
                window.location.reload();
            } else {
                result = false;
            }
        }
        return result;
    }
};