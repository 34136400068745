import React from 'react';
import {routes} from '../config/globales';
import { Link } from 'react-router-dom';

class Accueil extends React.Component {
    render() {
        return (
            <section id="main-slider" className="m-0">
                <div className="carousel slide">
                    <div className="carousel-inner">
                        <div className="item active" style={{background:"url(/assets/images/slider/bg1.jpg)"}}>
                            <div className="row">
                                <div className="col-sm-12 col-md-6 mr-auto">
                                    <div className="carousel-content m-sm-0 m-md-5 pr-sm-5 p-3 d-inline-flex align-items-center">
                                        <div className="align-middle">
                                            <h2 className="wow fadeInDown" id="message-anime">GL Pay - <span>Paiement en ligne</span></h2>
                                            <p className="font-weight-bold wow fadeInTop">Payez ou que vous soyez. Recevez de l'argent tel que vous le désirez.</p>
                                            <Link className="btn btn-success btn-lg font-weight-bold mb-2 mr-1 wow fadeInLeft" to="/creer-facture" data-wow-delay="500ms">Créer une facture</Link>
                                            <Link className="btn btn-outline-success btn-lg font-weight-bold text-light mb-2 mr-1 fadeInRight" to="/payer-facture" data-wow-delay="500ms">Payer une facture</Link>
                                            <Link className="btn btn-warning btn-lg font-weight-bold text-light mb-2 mr-1 fadeInLeft" to="/mobile-vers-mobile" data-wow-delay="500ms">Mobile vers Mobile</Link>
                                            <Link className="btn btn-info btn-lg font-weight-bold mb-2 mr-1 fadeInRight" to="/paiement-direct" data-wow-delay="500ms">Paiement direct</Link>
                                            <Link className="btn btn-secondary btn-lg font-weight-bold mb-2 fadeInRight" to="/payer-evenement" data-wow-delay="500ms">Evènements et billets</Link>
                                        </div>
                                    </div>
                                </div>
                    
                                <div className="col-sm-6 d-none d-md-inline wow fadeInRight" data-wow-delay="1s">
                                    <div className="m-5 p-3">
                                        <img src="assets/images/slider/img3.png" alt="GL Pay - Paiement et transfert d'argent simplifié en afrique" className="img-fluid" style={{borderRadius: "40%"}} />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="alert-secondary py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 col-md-6 text-justify">
                                <h2>Vos transactions sont sécurisés et votre identité protégées</h2>
                                <p>
                                    Nos requêtes sont protégés par cryptage pendant les échanges. Il n'est donc pas possible d'intercepter vos information sur notre serveur.
                                </p>
                                <p>
                                    Les information de cartes bancaires sont envoyé directement au processeur de paiements pour traitement. Les informations de votre carte ne sont pas stockées.
                                </p>
                                <p>
                                    En cas de fraude, nous restitions au autorités compétants (à la demande) les informations sur la transaction frauduleuse dans le but de facilité le pistage de l'argent malversé.
                                </p>
                                <p>
                                    Nous organisons des séminaires pour expliquer aux public comment fonctionne les paiements en ligne et comment se protéger du vol d'informations. pour en savoir plus, visitez notre chaine YouTube.
                                </p>
                                <Link className="btn btn-success btn-lg btn-sm-block mt-3 mr-2 wow fadeInLeft" to={routes[1].path}>Créer une facture</Link>
                                <Link className="btn btn-danger btn-lg btn-sm-block mt-3 mr-2 wow fadeInRight" to={routes[2].path}>Payer une facture</Link>
                                <Link className="btn btn-warning btn-lg btn-sm-block mt-3 mr-2 wow fadeInLeft" to={routes[5].path}>Mobile vers Mobile</Link>
                                <Link className="btn btn-info btn-lg btn-sm-block mt-3 wow fadeInRight" to={routes[4].path}>Paiement direct</Link>
                                <Link className="btn btn-secondary btn-lg font-weight-bold mb-2 fadeInRight" to="/payer-evenement" data-wow-delay="500ms">Evènements et billets</Link>
                            </div>
                            <div className="col-lg-7 col-md-6">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h2>Pourquoi nous choisir le paiement avec GL Pay ?</h2>
                                    </div>
                                    <div className="col-xl-4 col-md-6 col-sm-6">
                                        <div className="card card-body text-center mb-3">
                                            <div><span className="fa fa-credit-card fa-5x text-primary"></span></div>
                                            <h3>Carte bancaire</h3>
                                            <p className="mb-0">Cartes bancaires acceptées</p>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-md-6 col-sm-6">
                                        <div className="card card-body text-center mb-3">
                                            <div><span className="fa fa-mobile-phone fa-5x text-secondary"></span></div>
                                            <h3>Paiement mobile</h3>
                                            <p className="mb-0">Paiements mobiles acceptés</p>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-md-6 col-sm-6">
                                        <div className="card card-body text-center mb-3">
                                            <div><span className="fa fa-shield fa-5x text-success"></span></div>
                                            <h3>Hautement sécurisé</h3>
                                            <p className="mb-0">Transactions sécurisées et sûre</p>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-md-6 col-sm-6">
                                        <div className="card card-body text-center mb-3">
                                            <div><span className="fa fa-clock-o fa-5x text-danger"></span></div>
                                            <h3>Instantané</h3>
                                            <p className="mb-0">Transaction Hyper-Rapide</p>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-md-6 col-sm-6">
                                        <div className="card card-body text-center mb-3">
                                            <div><span className="fa fa-check-circle-o fa-5x text-warning"></span></div>
                                            <h3>Fiabilité</h3>
                                            <p className="mb-0">Fiabilité très guarantie</p>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-md-6 col-sm-6">
                                        <div className="card card-body text-center mb-3">
                                            <div><span className="fa fa-lock fa-5x text-success"></span></div>
                                            <h3>Protégé</h3>
                                            <p className="mb-0">Protection des données</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </section>
        );
    }
}

export default Accueil;