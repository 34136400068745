import React from 'react';

import TableDisplay from './TableDisplay';

import {Data} from '../../services/data-manager';
import { Modal } from 'react-bootstrap';
import Moment from 'react-moment';

class Evenements extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            evenements: [],
            page: 1,
            nb: 1,
            size: 50,
            evenement: null,
            showDetail: false
        };
        this.getEvenements = this.getEvenements.bind(this);
        this.getDetailEvenement = this.getDetailEvenement.bind(this);
        this.showDetailModal = this.showDetailModal.bind(this);
    }
    componentDidMount() {
        this.props.isLoading(true);
        this.getEvenements();
    }
    getEvenements(page=0) {
        if (page!==0) {
            this.setState({page: page});
        } else {
            page = this.state.page;
        }
        this.props.isLoading(true);
        new Data()
        .get("Evenement", "1", "utilisateur", "dateCreation DESC", page, this.state.size)
        .then(res => {
            res.rows.map(e => { e.nomInitiateur = e.utilisateur.nom + " " + e.utilisateur.prenom; })
            this.setState({evenements: res.rows, nb: res.count});
            this.props.isLoading(false);
        })
        .catch(err => {
            console.warn("Error : ", err);
            this.props.isLoading(false);
        });
        window.scrollTo(0,0);
    }
    getDetailEvenement(evenement) {
        new Data()
        .exec(`/PayerEvenement/sum?fields=nombrePlace&where=idEvenement=${evenement.id} AND etat='PAYEE'`)
        .then(res => {
            evenement.nombrePayee = res.value;
        })
        .catch(err => {
            console.log("Error : ", err);
        })
        .finally(() => {
            this.setState({evenement: evenement}, () => { this.showDetailModal(); });
        });
    }
    showDetailModal(show=true) {
        this.setState({showDetail: show});
    }
    cancelEvenement(evenement) {
        window.confirm("Voulez vous vraiment supprimer le paiement de billet "+evenement.id);
    }
    render() {
        return (
            <>
                <div className="row">
                    <div className="col-sm-12 my-3">
                        <h3>Evenements [Page {this.state.page}/{(this.state.nb===0)?1:Math.ceil(this.state.nb/this.state.size)}]</h3>
                    </div>
                    <div className="col-sm-12 mb-3">
                        <TableDisplay 
                            tableStriped={true}
                            tableHover={true}
                            thead="dark"
                            titles={["ID", "NOM", "INITIATEUR", "NB PLACES", "PRIX BILLET", "DEVISE", "DATE D'ENREGISTREMENT", "DATE DE CLOTURE", "DATE DE L'EVENEMENT"]}
                            indices={["id", "nom", "nomInitiateur", "nombrePlace", "prixBillet", "devise", "dateCreation", "dateCloture", "dateEvenement"]}
                            data={this.state.evenements}
                            actions={[
                                {caption: <i className='fa fa-search'></i>, className:"btn btn-sm btn-success mr-1", onClick:this.getDetailEvenement},
                                {caption: <i className='fa fa-trash'></i>, className:"btn btn-sm btn-danger", onClick:this.cancelEvenement}
                            ]}
                            withLink="/evenement/"
                            withPaging={true}
                            pagingTheme="success"
                            nb={this.state.nb}
                            size={this.state.size}
                            current={this.state.page}
                            onChangePage= {this.getEvenements}
                            withFooter={true} />
                    </div>
                </div>
                {(this.state.evenement)?(
                <Modal show={this.state.showDetail} onHide={() => {this.showDetailModal(false)}} className="mt-5">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Détails de l'évènement #{this.state.evenement.id}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="mx-3">
                            <table className="table table-sm">
                                <tr className="row">
                                    <td className="col-sm-5">Image : </td>
                                    <td className="col-sm-7">{(this.state.evenement.image)?<img src={'https://glpay.legrandsoft.com'+this.state.evenement.image} alt="GLPay - Image de l'évènement" />:<i>Aucune</i>}</td>
                                </tr>
                                <tr className="row">
                                    <td className="col-sm-5">ID : </td>
                                    <td className="col-sm-7">{this.state.evenement.id}</td>
                                </tr>
                                <tr className="row">
                                    <td className="col-sm-5">Nom : </td>
                                    <td className="col-sm-7">{this.state.evenement.nom}</td>
                                </tr>
                                <tr className="row">
                                    <td className="col-sm-5">Initiateur : </td>
                                    <td className="col-sm-7">{this.state.evenement.nomInitiateur}</td>
                                </tr>
                                <tr className="row">
                                    <td className="col-sm-5">Description : </td>
                                    <td className="col-sm-7">{this.state.evenement.description}</td>
                                </tr>
                                <tr className="row">
                                    <td className="col-sm-5">Montant du billet : </td>
                                    <td className="col-sm-7">{Intl.NumberFormat().format(this.state.evenement.prixBillet)} {this.state.evenement.devise}</td>
                                </tr>
                                <tr className="row">
                                    <td className="col-sm-5">Date d'enregistrement : </td>
                                    <td className="col-sm-7"><Moment format="DD/MM/YYYY à HH:mm">{this.state.evenement.dateCreation}</Moment></td>
                                </tr>
                                <tr className="row">
                                    <td className="col-sm-5">Date de l'évènement : </td>
                                    <td className="col-sm-7"><Moment format="DD/MM/YYYY à HH:mm">{this.state.evenement.dateEvenement}</Moment></td>
                                </tr>
                                <tr className="row">
                                    <td className="col-sm-5">Nombre de places : </td>
                                    <td className="col-sm-7">{(this.state.evenement.nombrePlace>0)?Intl.NumberFormat().format(this.state.evenement.nombrePlace):"Illimitées"}</td>
                                </tr>
                                <tr className="row">
                                    <td className="col-sm-5">Nombre de billets payés : </td>
                                    <td className="col-sm-7">{Intl.NumberFormat().format(this.state.evenement.nombrePayee)}{(this.state.evenement.nombrePlace>0)?' / '+this.state.evenement.nombrePlace:''}</td>
                                </tr>
                                <tr className="row">
                                    <td className="col-sm-5">Mode de reversion : </td>
                                    <td className="col-sm-7">{(this.state.evenement.mode)?this.state.evenement.mode:<i>Cash</i>}</td>
                                </tr>
                                <tr className="row">
                                    <td className="col-sm-5">Numéro de compte : </td>
                                    <td className="col-sm-7">{this.state.evenement.numeroCompte}</td>
                                </tr>
                                <tr className="row">
                                    <td className="col-sm-5">Nom du compte : </td>
                                    <td className="col-sm-7">{this.state.evenement.nomCompte}</td>
                                </tr>
                            </table>
                        </div>
                    </Modal.Body>
                    <Modal.Footer></Modal.Footer>
                </Modal>
                ):''}
            </>
        );
    }
}

export default Evenements;