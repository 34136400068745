import React from 'react';
import {Chart} from 'react-charts';
import moment from 'moment';
import { Data } from '../../services/data-manager';

export default class Accueil extends React.Component {
    nInter = null;
    constructor(props) {
        super(props);
        this.state = {
            nbTransactions: 0,
            totalTransactions: 0,
            solde: 0,
            nbTransactionsToutes: 0,
            nbTransactionsEchec: 0,
            nbTransactionsAnnule: 0
        }
        this.getStats = this.getStats.bind(this);
    }
    componentDidMount() {
        this.getStats();
        this.nInter = setInterval(this.getStats, 10000);
    }
    componentWillUnmount() {
        clearInterval(this.nInter);
    }
    getStats() {
        if (this.props.marchands) {
            if (this.props.marchands.length>0) {
                // Nombre de transaction réussi
                new Data()
                .exec(`/PaiementMarchand/count?fields=id&where=idMarchand='${this.props.marchands[0].id}' AND status='SUCCESSFUL'`)
                .then(res => {
                    if (res) this.setState({nbTransactions: res.value});
                })
                .catch(err => {
                    console.warn("Error : ", err);
                });
                // Montant total des transactions réuissi
                new Data()
                .exec(`/PaiementMarchand/sum?fields=montant&where=idMarchand='${this.props.marchands[0].id}' AND status='SUCCESSFUL'`)
                .then(res => {
                    if (res.value) this.setState({totalTransactions: res.value});
                })
                .catch(err => {
                    console.warn("Error : ", err);
                });
                // Solde du marchand
                new Data()
                .exec(`/Marchand/getone/${this.props.marchands[0].id}`)
                .then(res => {
                    if (res.solde) this.setState({solde: res.solde});
                })
                .catch(err => {
                    console.warn("Error : ", err);
                });
                // Nombre totla de toutes les transactions
                new Data()
                .exec(`/PaiementMarchand/count?fields=id&where=idMarchand='${this.props.marchands[0].id}'`)
                .then(res => {
                    if (res) this.setState({nbTransactionsToutes: res.value});
                })
                .catch(err => {
                    console.warn("Error : ", err);
                });
                // Nombre de transaction échoués
                new Data()
                .exec(`/PaiementMarchand/count?fields=id&where=idMarchand='${this.props.marchands[0].id}' AND status='FAILED'`)
                .then(res => {
                    if (res) this.setState({nbTransactionsEchec: res.value});
                })
                .catch(err => {
                    console.warn("Error : ", err);
                });
                // Nombre de transactions annulées
                new Data()
                .exec(`/PaiementMarchand/count?fields=id&where=idMarchand='${this.props.marchands[0].id}' AND status='CANCEL'`)
                .then(res => {
                    if (res) this.setState({nbTransactionsAnnule: res.value});
                })
                .catch(err => {
                    console.warn("Error : ", err);
                });
            }
        }
    }
    render() {
        return (
            <>
                <div className="row">
                    <div className="col-md-12 my-5 text-center">
                        <h2>{this.props.marchands[0].nom}</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 mb-5 text-center">
                        <h3>TRANSACTIONS R&Eacute;USSIS</h3>
                    </div>
                    <div className="col-md-4">
                        <div className="card text-center">
                            <div className="card-header">NOMBRE DE TRANSACTIONS</div>
                            <div className="card-body">
                                <h3 className="text-info">{Intl.NumberFormat().format(this.state.nbTransactions)}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card text-center">
                            <div className="card-header">TOTAL DES TRANSACTIONS</div>
                            <div className="card-body">
                                <h3 className="text-success">{Intl.NumberFormat().format(this.state.totalTransactions)}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card text-center">
                            <div className="card-header">SOLDE DU COMPTE MARCHAND</div>
                            <div className="card-body">
                                <h3 className="text-info">{Intl.NumberFormat().format(this.state.solde)}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-5"></div>
                </div>
                <div className="row">
                    <div className="col-md-12 mb-5 text-center">
                        <h3>TOUTES LES TRANSACTIONS</h3>
                    </div>
                    <div className="col-md-4">
                        <div className="card text-center">
                            <div className="card-header">NOMBRE DE TRANSACTIONS TOTALES</div>
                            <div className="card-body">
                                <h3 className="text-primary">{Intl.NumberFormat().format(this.state.nbTransactionsToutes)}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card text-center">
                            <div className="card-header">NOMBRE DE TRANSACTIONS ECHOU&Eacute;ES</div>
                            <div className="card-body">
                                <h3 className="text-danger">{Intl.NumberFormat().format(this.state.nbTransactionsEchec)}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card text-center">
                            <div className="card-header">NOMBRE DE TRANSACTIONS ANNUL&Eacute;ES</div>
                            <div className="card-body">
                                <h3 className="text-warning">{Intl.NumberFormat().format(this.state.nbTransactionsAnnule)}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-5"></div>
                </div>
            </>
        );
    }
}