import React from 'react';
import Notifications from 'react-notify-toast';

import Paiement from './Paiement';

import {devisesAcceptes, modesPaiements, TAUX_BANK, montantDansMinMax, montantMin, montantMax} from '../config/globales';
import {Data, Pays} from '../services/data-manager';
import {notifyDanger, notifySuccess} from '../utils';

class PaiementDirect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newFacture: {
				utilisateur: {
					nom: "",
					prenom: "",
					nomUtilisateur: "",
					sexe: "",
					dateNaissance: "",
					piece: "",
					numeroPiece: "",
					idPays: "",
					ville: "",
					adresse: "",
					email: "",
					telephone: "",
					commentaire: "",
					type: 0,
					pass: "",
					dateCreation: new Date(),
					estNouveau: true,
					estBloque: false,
					estConnecte: true,
					dateConnecte: new Date(),
					dateActif: new Date(),
					langue: "fr"
				},
				services: [{
					nom: "",
					quantite: "",
					montant: 0,
					description: "",
					etat: "Complet"
				}],
				payeur: {
					nom: "",
					prenom: "",
					nomUtilisateur: "",
					sexe: "",
					dateNaissance: "",
					piece: "",
					numeroPiece: "",
					idPays: "",
					ville: "",
					adresse: "",
					email: "",
					telephone: "",
					commentaire: "",
					type: 0,
					pass: "",
					dateCreation: new Date(),
					estNouveau: true,
					estBloque: false,
					estConnecte: true,
					dateConnecte: new Date(),
					dateActif: new Date(),
					langue: "fr"
				},
				numero: "",
				etat: "En attente",
				mode: "",
				numeroCompte: "",
				total: 0.00,
				frais: 0.00,
				aPayer: 0.00,
				aPercevoir: 0.00,
				devise: "XAF",
				dateCreation: new Date(),
				dateModification: new Date(),
                conditions: true,
                quiSupporte: "Bénéficiaire"
            },
            pays: []
        };
        this.enregistreFacture = this.enregistreFacture.bind(this);
        this.savePaiementDirect = this.savePaiementDirect.bind(this);
    }

    componentDidMount() {
        let pays = new Data()
        .get("Pays", "1", "", "pays")
        .then(res => {
            this.setState({pays: res.rows});
        });
    }

    handleInputUtilisateur(event) {
        const target = event.target;
        var newFacture = this.state.newFacture;
        if (target.type==="checkbox") {
            if (target.checked) {
                newFacture.utilisateur[target.name] = target.hasAttribute("true-value")?target.getAttribute("true-value"):target.checked;
            } else {
                newFacture.utilisateur[target.name] = target.hasAttribute("false-value")?target.getAttribute("false-value"):target.checked;
            }
        } else {
            newFacture.utilisateur[target.name] = target.value;
        }
        this.setState({newFacture: newFacture});
    }
    handleInputPayeur(event) {
        const target = event.target;
        var newFacture = this.state.newFacture;
        if (target.type==="checkbox") {
            if (target.checked) {
                newFacture.payeur[target.name] = target.hasAttribute("true-value")?target.getAttribute("true-value"):target.checked;
            } else {
                newFacture.payeur[target.name] = target.hasAttribute("false-value")?target.getAttribute("false-value"):target.checked;
            }
        } else {
            newFacture.payeur[target.name] = target.value;
        }
        this.setState({newFacture: newFacture});
    }
    handleInputFacture(event) {
        const target = event.target;
        var newFacture = this.state.newFacture;
        if (target.type==="checkbox") {
            if (target.checked) {
                newFacture[target.name] = target.hasAttribute("true-value")?target.getAttribute("true-value"):target.checked;
            } else {
                newFacture[target.name] = target.hasAttribute("false-value")?target.getAttribute("false-value"):target.checked;
            }
        } else {
            newFacture[target.name] = target.value;
        }
        if (target.name==="quiSupporte") {
            newFacture = this.claculTotal(newFacture);
        }
        this.setState({newFacture: newFacture});
    }
    handleInputService(index, event) {
        const target = event.target;
        var newFacture = this.state.newFacture;
        newFacture.services[index][target.name] = target.value;
        if (target.name==="montant") {
            newFacture = this.claculTotal(newFacture);
        }
        this.setState({newFacture: newFacture});
    }
    claculTotal(newFacture) {
        var total = 0;
        newFacture.services.forEach(service => {
            total += +service.montant;
        });
        newFacture.total = total;
        newFacture.frais = total * TAUX_BANK / 100;
        if (newFacture.quiSupporte==="Payeur") {
            newFacture.aPayer = total + newFacture.frais;
            newFacture.aPercevoir = total;
        } else {
            newFacture.aPayer = total;
            newFacture.aPercevoir = total - newFacture.frais;
        }
        return newFacture;
    }
    addService() {
        var newFacture = this.state.newFacture;
        newFacture.services.push({
            nom: "",
            quantite: "",
            montant: 0,
            description: "",
            etat: "Complet"
        });
        this.setState({newFacture: newFacture});
    }

    
    enregistreFacture(e) {
        e.preventDefault();
        let newFacture = this.state.newFacture;
        console.log(newFacture);
        let verif = montantDansMinMax(newFacture.total, newFacture.devise, montantMin(), montantMax());
        if (verif!==true) {
            if (verif===false) {
                notifyDanger("Vérifiez que vous avez choisi une dévise et entrez un montant correct.");
            } else {
                notifyDanger(`Le montant doit être compris ${verif[0]} et ${verif[1]}.\nVous pouvez enregistrer plusieurs factures.`);
            }
            return false;
        }
        notifyDanger("Choisissez votre mode de paiement et la validation sera automatique après que la transaction soit effectuée.");
        return false;
    }

    savePaiementDirect(aData=null) {
        let newFacture = this.state.newFacture;
        newFacture.aData = aData;
        new Data().send("/paiement-direct", newFacture)
        .then(res => {
            console.log(res);
            notifySuccess("L'enregistrement du payement à été effectué.<br>Votre bénéficiaire le recevra dans la minute qui suit.");
            this.setState({
                newFacture: {
                    payeur: newFacture.payeur,
                    services: [{
                        nom: "",
                        quantite: "",
                        montant: 0,
                        description: "",
                        etat: "Complet"
                    }],
                    utilisateur: {
                        nom: "",
                        prenom: "",
                        nomUtilisateur: "",
                        sexe: "",
                        dateNaissance: "",
                        piece: "",
                        numeroPiece: "",
                        idPays: "",
                        ville: "",
                        adresse: "",
                        email: "",
                        telephone: "",
                        commentaire: "",
                        type: 0,
                        pass: "",
                        dateCreation: new Date(),
                        estNouveau: true,
                        estBloque: false,
                        estConnecte: true,
                        dateConnecte: new Date(),
                        dateActif: new Date(),
                        langue: "fr"
                    },
                    numero: "",
                    etat: "En attente",
                    mode: "",
                    numeroCompte: "",
                    total: 0.00,
                    frais: 0.00,
                    aPayer: 0.00,
                    aPercevoir: 0.00,
                    devise: "XAF",
                    dateCreation: new Date(),
                    dateModification: new Date(),
                    conditions: true,
                    quiSupporte: "Bénéficiaire"
                }
            })
        })
        .catch(err => {
            notifyDanger("L'enregistrement du payement à échoué.<br>Veuillez reéssayer !");
            console.warn("Error while saving payment : ", err);
        });
    }



    render() {
        return (
            <section id="paiement-direct" className="alert-secondary py-3">
                <Notifications />
                <div className="container-fluid">
                    <form action="/paiement-direct" name="paiementDirect" method="post" className="contactForm" onSubmit={this.enregistreFacture}>
                        <div className="card">
                            <div className="card-header">
                                <h1 className="text-dark">Paiement direct</h1>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="card card-body text-dark">
                                            <h3>Informations du payeur</h3>
                                            <hr className="border-secondary" />
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label className="form-label">Nom</label>
                                                    <input name="nom" type="text" value={this.state.newFacture.payeur.nom} className="form-control border-secondary" placeholder="Nom" data-rule="minlen:3" data-msg="Entrez un nom correct" required autoFocus onChange={(e) => {this.handleInputPayeur(e)}} />
                                                    <div className="validation"></div>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label className="form-label">Prénom</label>
                                                    <input name="prenom" type="text" value={this.state.newFacture.payeur.prenom} className="form-control border-secondary" placeholder="Prénom" data-rule="minlen:3" data-msg="Entrez un prénom correct" required onChange={(e) => {this.handleInputPayeur(e)}} />
                                                    <div className="validation"></div>
                                                </div>
                                            </div>
                                            {/*
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label className="form-label">Pièce d'identité *</label>
                                                    <select name="piece" value={this.state.newFacture.payeur.piece} className="form-control border-secondary" data-rule="minlen:1" data-msg="Choisissez une pièce d'identité dont vous possédé" required onChange={(e) => {this.handleInputPayeur(e)}} />
                                                        <option value=""></option>
                                                        {( piecesIdentites.map((p, i) => <option value={ p } key={'piece-identite-'+i}>{ p }</option>) )}
                                                    </select>
                                                    <div className="validation"></div>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label className="form-label">Numero de pièce *</label>
                                                    <input name="numeroPiece" type="text" value={this.state.newFacture.payeur.numeroPiece} className="form-control border-secondary" placeholder="Numéro d'identité" data-rule="minlen:4" data-msg="Entrez le numéro de pièce d'identité valide" required onChange={(e) => {this.handleInputPayeur(e)}} />
                                                    <div className="validation"></div>
                                                </div>
                                            </div>
                                            */}
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label className="form-label">Pays *</label>
                                                    <select name="idPays" value={this.state.newFacture.payeur.idPays} className="form-control border-secondary" data-rule="minlen:2" required onChange={(e) => {this.handleInputPayeur(e)}}>
                                                        <option value=""></option>
                                                        { this.state.pays.map(p => <option value={p.id} key={'pays-'+p.id}>{ p.pays }</option>) }
                                                    </select>
                                                    <div className="validation"></div>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label className="form-label">Ville *</label>
                                                    <input name="ville" type="text" value={this.state.newFacture.payeur.ville} className="form-control border-secondary" placeholder="Ville de résidence" data-rule="minlen:3" data-msg="Entrez une ville correct" required onChange={(e) => {this.handleInputPayeur(e)}} />
                                                    <div className="validation"></div>
                                                </div>
                                            </div>
                                            {/*
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <label className="form-label">Adresse *</label>
                                                    <input name="adresse" type="text" value={this.state.newFacture.payeur.adresse} className="form-control border-secondary" placeholder="Adresse de residence" data-rule="minlen:3" data-msg="Entrez une adresse correct" required onChange={(e) => {this.handleInputPayeur(e)}} />
                                                    <div className="validation"></div>
                                                </div>
                                            </div>
                                            */}
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label className="form-label">E-mail</label>
                                                    <input name="email" type="text" value={this.state.newFacture.payeur.email} className="form-control border-secondary" placeholder="E-mail" onChange={(e) => {this.handleInputPayeur(e)}} />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label className="form-label">Téléphone</label>
                                                    <input name="telephone" type="text" value={this.state.newFacture.payeur.telephone} className="form-control border-secondary" placeholder="Téléphone" onChange={(e) => {this.handleInputPayeur(e)}} />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="col-md-12 mt-5">
                                                    <h3>Informations du bénéficiaire</h3>
                                                </div>
                                            </div>
                                            <hr className="border-secondary" />
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <label className="form-label">Nom du bénéficiaire ou de l'entreprise *</label>
                                                    <input name="nomUtilisateur" type="text" value={this.state.newFacture.utilisateur.nomUtilisateur} className="form-control border-secondary" placeholder="Nom du bénéficiaire" onChange={(e) => {this.handleInputUtilisateur(e)}} />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label className="form-label">E-mail</label>
                                                    <input name="email" type="text" value={this.state.newFacture.utilisateur.email} className="form-control border-secondary" placeholder="E-mail" onChange={(e) => {this.handleInputUtilisateur(e)}} />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label className="form-label">Téléphone (avec code) *</label>
                                                    <input name="telephone" type="text" value={this.state.newFacture.utilisateur.telephone} className="form-control border-secondary" placeholder="Exemple : +237690000075" onChange={(e) => {this.handleInputUtilisateur(e)}} />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <div className="custom-control custom-checkbox">
                                                        <input name="quiSupporte" type="checkbox" className="custom-control-input" id="cbQuiSupporte" true-value="Payeur" false-value="Bénéficiaire" checked={this.state.newFacture.quiSupporte==="Payeur"} onChange={(e) => {this.handleInputFacture(e)}} />
                                                        <label className="custom-control-label" for="cbQuiSupporte">C'est moi qui supporte les frais de transaction.</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="col-md-12 mt-5">
                                                    <h3>Mode de réception du paiement</h3>
                                                </div>
                                            </div>
                                            <hr className="border-secondary" />
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label className="form-label">Mode</label>
                                                    <select name="mode" value={this.state.newFacture.mode} className="form-control border-secondary" onChange={(e) => {this.handleInputFacture(e)}}>
                                                        <option value=""></option>
                                                        {( modesPaiements.map((m, i) => <option value={ m } key={'mode-paiement-'+i}>{ m }</option>) )}
                                                    </select>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label className="form-label">Numéro de compte / téléphone</label>
                                                    <input name="numeroCompte" type="text" value={this.state.newFacture.numeroCompte} className="form-control border-secondary" placeholder="Compte du bénéficiaire" onChange={(e) => {this.handleInputFacture(e)}} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="card card-body text-dark">
                                            <h3>Paiement</h3>
                                            <hr className="border-secondary" />
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <label className="form-label">Motif du paiement *</label>
                                                    <textarea name="nom" value={this.state.newFacture.services[0].nom} rows="3" className="form-control border-secondary" placeholder="Décrivez ici la raison pour laquelle vous faites le paiement au bénéficiaire." onChange={(e) => {this.handleInputService(0, e)}}></textarea>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-sm-10 mr-sm-0 pr-sm-0">
                                                    <label className="form-label">Montant</label>
                                                    <input name="montant" type="text" value={this.state.newFacture.total} className="form-control border-secondary" id="newFacture_total" placeholder="Montant" onChange={(e) => {this.handleInputService(0, e)}} />
                                                </div>
                                                <div className="form-group col-sm-2 ml-sm-0 pl-sm-0">
                                                    <label className="form-label">&nbsp;</label>
                                                    <select name="devise" value={this.state.newFacture.devise} className="form-control border-secondary border-left-0" onChange={(e) => {this.handleInputFacture(e)}}>
                                                        {( devisesAcceptes.map(d => <option value={d.nom} key={'devise-'+d.nom}>{ d.nom }</option>) )}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <label className="form-label">Frais de transaction</label>
                                                    <input type="text" value={this.state.newFacture.frais} className="form-control border-secondary" placeholder="Frais de transaction" readOnly />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <label className="form-label">Net à payer</label>
                                                    <input type="text" value={this.state.newFacture.aPayer} className="form-control border-secondary" placeholder="Montant à payer" readOnly />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <label className="form-label">Net à percevoir</label>
                                                    <input type="text" value={this.state.newFacture.aPercevoir} className="form-control border-secondary" placeholder="Montant à percevoir" readOnly />
                                                </div>
                                            </div>
                                            <div className="form-row mb-5">
                                                <div className="form-group col-md-12">
                                                    <div className="custom-control custom-checkbox">
                                                        <input name="conditions" type="checkbox" className="custom-control-input" id="cbCondition" checked={this.state.newFacture.conditions} onChange={(e) => {this.handleInputFacture(e)}} />
                                                        <label className="custom-control-label" for="cbCondition">J'accepte les termes et conditions d'utilisations et de tarifications (<a href="/conditions.php" target="_blank">disponibles ici</a>)</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <Paiement onPay={this.savePaiementDirect} parent="PaiementDirect" client={{
                                                    nom: this.state.newFacture.payeur.nom,
                                                    prenom: this.state.newFacture.payeur.prenom,
                                                    email: this.state.newFacture.payeur.email,
                                                    telephone: this.state.newFacture.payeur.telephone,
                                                    montant: this.state.newFacture.aPayer,
                                                    devise: this.state.newFacture.devise
                                                }} 
                                                mobilePayData={{
                                                    facture: this.state.newFacture
                                                }} 
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer text-right">
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        );
    }
}

export default PaiementDirect;