import React from 'react';
import {Link/*, useRouteMatch, matchPath*/} from 'react-router-dom';
import {Button, Modal} from 'react-bootstrap';

import {routes} from '../config/globales';


class Header extends React.Component {
    render() {
        //let match = useRouteMatch();
        return (
            <header>
                <nav className="navbar navbar-light bg-light navbar-expand-lg navbar-fixed-top" role="navigation">
                    <a className="navbar-brand py-0" href="#">
				        <img src="assets/images/logo.jpeg" alt="GL Pay - Paiement et transfert d'argent simplifié" />
                        {/*<h1><span>GL</span>Pay</h1>*/}
                    </a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#menu" aria-controls="menu" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="navbar-collapse collapse menu" id="menu">
                        <ul className="navbar-nav ml-auto" role="tablist">
                            {(
                                routes.map((route, index) => {
                                    if (route.inMenu) {
                                        return (
                                            <li className="nav-item" role="presentation" key={'menu-'+route.name+'-'+index}>
                                                <Link className="nav-link" onClick={(e) => {document.querySelector("button.navbar-toggler").click()}} to={route.path}>{ route.label }</Link>
                                            </li>
                                        )
                                    }
                                    return (
                                        <li className="nav-item d-none" role="presentation" key={'menu-'+route.name+'-'+index}>
                                            <Link className="nav-link" data-toggle="collapse" data-target=".navbar-collapse.collapse" to={route.path}>{ route.label }</Link>
                                        </li>
                                    );
                                })
                            )}
                            {this.props.withLogout && (
                                <li className="nav-item" role="presentation">
                                    <span className="nav-link text-danger" data-toggle="collapse" data-target=".navbar-collapse.collapse" style={{cursor: "pointer"}} onClick={this.props.auth.deconnexion}>Déconnexion</span>
                                </li>
                            )}
                        </ul>
                    </div>
                </nav>
            </header>
        );
    }
}

class Footer extends React.Component {
    render() {
        return (
            <footer>
                <div className="footer bg-primary">
                    <div className="container py-3">
                        <div className="row">
                            <div className="col-sm-12 col-md-6 text-sm-center text-md-left">
                                <ul className="social-icon d-flex list-style-none justify-content-center pl-0">
                                    <li><a href="#" target="new" className="facebook" title="GLPay sur Facebook" data-toggle="tooltip"><i className="fa fa-facebook-square"></i></a></li>
                                    <li><a href="#" target="new" className="twitter" title="GLPay sur Twitter" data-toggle="tooltip"><i className="fa fa-twitter-square"></i></a></li>
                                    <li><a href="#" target="new" className="google-plus" title="GLPay sur Google Plus" data-toggle="tooltip"><i className="fa fa-google-plus-official"></i></a></li>
                                    <li><a href="#" target="new" className="linkedin" title="GLPay sur Linkedin" data-toggle="tooltip"><i className="fa fa-linkedin-square"></i></a></li>
                                    <li><a href="#" target="new" className="youtube" title="GLPay sur You Tube" data-toggle="tooltip"><i className="fa fa-youtube-play"></i></a></li>
                                </ul>
                            </div>

                            <div className="col-sm-12 col-md-6 text-sm-center text-md-right">
                                <div className="copyright">
                                    &copy; GLPay 2020. Tous droits reservés.
                                    <div className="credits">
                                        Développé et hébergé par l'entreprise <a href="https://legrandsoft.com/" target="_black" style={{fontSize: "15px"}}>Groupe legrandsoft</a>.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="pull-right text-right">
                        <a href="#home" className="scrollup"><i className="fa fa-angle-up fa-3x"></i></a>
                    </div>
                </div>
            </footer>
        );
    }
}

class StatusModal extends React.Component {
    render() {
        return (
            <Modal show={this.props.show} onHide={() => {this.props.show=false;}}>
                <Modal.Header closeButton>
                    <Modal.Title>Paiement encours</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h3 className="py-5 my-5">En attente de validation...</h3>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>
        );
    }
}

class YUPModal extends React.Component {
    render() {
        return (
            <Modal show={this.props.show} onHide={() => {this.props.show = false;}}>
                <Modal.Header closeButton>
                    <Modal.Title>Code YUP</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label>Code YUP</label>
                        <input type="number" v-model="yup.code" className="form-control border-secondary" placeholder="Entrer le code de sécurité YUP" />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {this.props.show=false;}}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={this.props.confirmationYUP}>
                        Confirmer
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

class Breadcrumb extends React.Component {
    render() {
        return (
            <div className="container">
                <div className="breadcrumb">
                    <li><Link to={routes[0].path}>{ routes[0].label }</Link></li>
                    <li className="text-secondary mx-2" style={{fontSize:"24px"}}>&gt;</li>
                    <li className="text-dark d-flex">{ this.props.text }</li>
                </div>
            </div>
        );
    }
}

class Loading extends React.Component {
    render() {
        if (this.props.show) {
            return (
                <div className="loading wow fadeInUp">
                    <img src="/assets/images/glpay-loading.gif" alt="GL Pay - Paiement et Transfert d'argent en un clic" />
                </div>
            );
        }
        return <div className="d-none"></div>;
    }
}

export {Header, Footer, StatusModal, YUPModal, Breadcrumb, Loading};