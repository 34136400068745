import React from 'react';

import {notifyDanger} from '../../utils/index';

export default class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            remember: false
        };
        this.handleInput = this.handleInput.bind(this);
        this.handleLoginForm = this.handleLoginForm.bind(this);
    }
    componentDidMount() {
    }
    handleInput(event) {
        const target = event.target;
        let name = event.target.name;
        var value;
        if (target.type==="checkbox") {
            if (target.checked) {
                value = target.hasAttribute("true-value")?target.getAttribute("true-value"):target.checked;
            } else {
                value = target.hasAttribute("false-value")?target.getAttribute("false-value"):target.checked;
            }
        } else {
            value = target.value;
        }
        this.setState({[name]: value});
    }
    handleLoginForm(event) {
        event.preventDefault();
        this.props.auth.login(this.state.username, this.state.password, this.state.remember)
        .then(ok => {
            if (!ok) {
                notifyDanger("Login ou mot de passe incorrecte.");
            }
        });
    }
    render() {
        return (
            <>
                <div className="row">
                    <div className="col-sm-12 my-3 text-center">
                        <h3>Connexion</h3>
                        <div>Bienvenu dans votre tableau de bord ! Connectez vous pour voir vos transactions.</div>
                    </div>
                    <div className="col-sm-12 mb-3">
                        <div className="row">
                            <div className="col-sm-10 col-md-8 col-lg-6 col-xl-4 mx-auto">
                                <form className="card" onSubmit={this.handleLoginForm}>
                                    <div className="card card-body">
                                        <div className="form-group">
                                            <label>Email ou Numéro de téléphone</label>
                                            <input className="form-control" type="text" name="username" placeholder="Login" value={this.state.username} onChange={this.handleInput} required />
                                        </div>
                                        <div className="form-group">
                                            <label>Mot de passe</label>
                                            <input className="form-control" type="password" name="password" placeholder="Mot de passe" value={this.state.password} onChange={this.handleInput} required />
                                        </div>
                                        <div className="form-group">
                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" id="remember-me" name="remember" checked={this.state.remember} onChange={this.handleInput} />
                                                <label className="custom-control-label" htmlFor="remember-me">Se souvenir de moi plutard.</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer text-right">
                                        <button type="submit" className="btn btn-primary">Me Connecter</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}