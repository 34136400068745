import React from 'react';
import Moment from 'react-moment';
import 'moment-timezone';
import { Link } from 'react-router-dom';
import { data } from 'jquery';

class TableDisplay extends React.Component {
    static defaultProps = {
        titles: ["Champs 1"],
        indices: [],
        data: {},
        table: true,
        tableStriped: false,
        tableHover: false,
        thead: "primary",
        actions: [],
        withLink: false,
        withPaging: false,
        nb: 0,
        size: 100,
        current: 1,
        pagingTheme: "primary",
        onClick: (data) => {},
        onChangePage: (page) => {}
    }
    render() {
        // Table classes
        let tableClass = "";
        if (this.props.table) tableClass += "table ";
        if (this.props.tableStriped) tableClass += "table-striped ";
        if (this.props.tableHover) tableClass += "table-hover ";
        // Pagination
        let NB = 1;
        let TAB_PAGES = [];
        if (this.props.withPaging) {
            NB = (this.props.nb===0)?1:Math.ceil(this.props.nb/this.props.size);
            for(let i=0; i<NB; i++) TAB_PAGES.push(i+1);
        }
        // Computation
        let TOTAL = {};
        // Output
        return (
            <div className="table-pane">
                <table className={tableClass}>
                    <thead className={"thead-"+this.props.thead}>
                        <tr>
                            {this.props.titles.map(title => <th>{title}</th>)}
                            {(this.props.actions.lenght!==0)?<th>Actions</th>:''}
                        </tr>
                    </thead>
                    <tbody>
                        {
                        this.props.data.map(d => (
                        <tr onClick={() => {this.props.onClick(d)}}>
                            {this.props.indices.map((indice, i) => {
                                let value = d[indice];
                                let type = typeof value;
                                let align = "left";
                                if (!TOTAL[indice]) {
                                    TOTAL[indice] = 0; //(type==="number" || type==="bigint")?:"";
                                }
                                if (type==="number" || type==="bigint" || (type==="string" && !isNaN(value))) {
                                    TOTAL[indice] += +value;
                                    value = new Intl.NumberFormat().format(value);
                                    align="right";
                                } else if (type==="boolean") {
                                    value = <i>{(value)?"Oui":"Non"}</i>;
                                } else if (value!==null) {
                                    if (value.match(/^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/)!==null) {
                                        value = <Moment format="DD/MM/YYYY à HH:mm">{value}</Moment>;
                                    }
                                }
                                return <td align={align}>{(i===0 && this.props.withLink)?<Link to={this.props.withLink+d[indice]}>{value}</Link>:value}</td>
                            })}
                            {(this.props.actions.lenght!==0)?<td>{this.props.actions.map(action => <button type="button" className={action.className} onClick={()=>{action.onClick(d)}}>{action.caption}</button>)}</td>:''}
                        </tr>
                        ))}
                    </tbody>
                    {(this.props.withFooter)?(
                    <tfoot className={"thead-"+this.props.thead}>
                        <tr>
                            {Object.keys(TOTAL).map((indice, i) => <th>{(i===0)?"":new Intl.NumberFormat().format(TOTAL[indice])}</th>)}
                            {(this.props.actions.lenght!==0)?<th></th>:''}
                        </tr>
                    </tfoot>
                    ):''
                    }
                </table>
                {(NB>1)?(
                <div className="text-center">
                    {TAB_PAGES.map((i) => <div className={"btn btn-"+((i===this.props.current)?"outline-":"")+this.props.pagingTheme+" mr-1"} onClick={() => {if (i!==this.props.current) {this.props.onChangePage(i)}}}>{i}</div>
                    )}
                </div>
                ):''
                }
            </div>
        );
    }
}

export default TableDisplay;