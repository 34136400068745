import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route, Link
} from 'react-router-dom';

import './App.css';

import {Header, Footer, Breadcrumb, Loading} from './components/Body';

import {routes} from './config/globales';
import Accueil from './components/admin/Accueil';
import Factures from './components/admin/Factures';
import MobileVersMobile from './components/admin/MobileVersMobile';
import DetailEvenement from './components/admin/DetailEvenement';
import Evenements from './components/admin/Evenements';
import Transactions from './components/admin/Transactions';


class AppAdmin extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
    this.animationTraitement = this.animationTraitement.bind(this);
  }

  componentDidMount() {
    this.animationTraitement(false);
  }

  animationTraitement(isAnimated) {
    this.setState({loading: isAnimated});
  }


  render() {
    return (
      <Router>
        <Loading show={this.state.loading} />
        <Header />
        <div className="container my-3">
          <div className="row">
            <div className="col-md-12">
              <Switch>
                <Route exact path="/">
                  <Accueil isLoading={this.animationTraitement} />
                </Route>
                <Route path="/mobile-vers-mobile">
                  <Breadcrumb text="Mobile vers mobile" />
                  <MobileVersMobile isLoading={this.animationTraitement} />
                </Route>
                <Route path="/factures">
                  <Breadcrumb text="Factures" />
                  <Factures isLoading={this.animationTraitement} />
                </Route>
                <Route path="/evenements">
                  <Breadcrumb text="Evènements et billets" />
                  <Evenements isLoading={this.animationTraitement} />
                </Route>
                <Route path="/evenement/:id">
                  <Breadcrumb text={
                      <>
                        <Link to="/evenements">Evènements</Link>
                        <span className="text-secondary mx-2" style={{fontSize:"24px"}}>&gt;</span>
                        Detail de l'évènement
                      </>
                    } />
                  <DetailEvenement isLoading={this.animationTraitement} />
                </Route>
                <Route path="/transactions">
                  <Breadcrumb text="Transactions" />
                  <Transactions isLoading={this.animationTraitement} />
                </Route>
              </Switch>
            </div>
          </div>
        </div>
        <Footer />
      </Router>
    );
  }

}

export default AppAdmin;