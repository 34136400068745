import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route, Link
} from 'react-router-dom';

import Auth from './Auth';

import './App.css';

import {Header, Footer, Breadcrumb, Loading} from './components/Body';

import {routes} from './config/globales';
import Accueil from './components/marchand/Accueil';
import Login from './components/marchand/Login';
import PaiementMarchand from './components/marchand/PaiementMarchand';


export default class AppMarchand extends React.Component {
  nInterval = null;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      auth: new Auth()
    };
    this.animationTraitement = this.animationTraitement.bind(this);
  }

  componentDidMount() {
    this.animationTraitement(false);
    this.nInterval = setInterval(this.state.auth.isAuthenticated(), 10 * 60 * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.nInterval);
  }

  animationTraitement(isAnimated) {
    this.setState({loading: isAnimated});
  }

  render() {
    return (
      <Router>
        <Loading show={this.state.loading} />
        <Header withLogout={this.state.auth.isAuth} auth={this.state.auth} />
        <div className="container my-3">
          <div className="row">
            <div className="col-md-12">
              <Switch>
                <Route exact path="/">
                  {this.state.auth.isAuth && <Accueil isLoading={this.animationTraitement} marchands={this.state.auth.utilisateur.marchands} />}
                  {!this.state.auth.isAuth && <Login isLoading={this.animationTraitement} auth={this.state.auth} />}
                </Route>
                <Route path="/login">
                  <Login isLoading={this.animationTraitement} auth={this.state.auth} />
                </Route>
                <Route path="/transactions">
                  {this.state.auth.isAuth &&
                  <>
                    <Breadcrumb text="Transactions" />
                    <PaiementMarchand isLoading={this.animationTraitement} marchands={this.state.auth.utilisateur.marchands} />
                  </>
                  }
                  {!this.state.auth.isAuth && <Login isLoading={this.animationTraitement} auth={this.state.auth} />}
                </Route>
                <Route exact path="*">
                  {this.state.auth.isAuth && <Accueil isLoading={this.animationTraitement} marchands={this.state.auth.utilisateur.marchands} />}
                  {!this.state.auth.isAuth && <Login isLoading={this.animationTraitement} auth={this.state.auth} />}
                </Route>
              </Switch>
            </div>
          </div>
        </div>
        <Footer />
      </Router>
    );
  }

}