import React from 'react';

class Apropos extends React.Component {
    render() {
        return (
            <section id="apropos" className="alert-secondary text-dark py-3">
                <div className="aboutus" id="info">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2>GL Pay</h2>
                                <hr className="border-secondary" />
                            </div>
                            <hr />
                            <div className="col-md-7 wow fadeInDown" data-wow-duration="1000ms" data-wow-delay="300ms">
                                <h2>Transactions financières en temps réel</h2>
                                <p>
                                    GL Pay est un portail vous permettant d'effectuer les transactions de comptes à comptes 
                                    sans restriction de la source ou de la destination. Ce portail à été conçu 
                                    pour permettre au personnes et au entreprises de payer des produits et des services 
                                    sans difficultés ni intervention d'une partie tièces.
                                </p>
                                <p>
                                    GL Pay est une initiative de l'entreprise 
                                    <a href="https://legrandsoft.com" target="_blank" rel="noopener noreferrer">Groupe legrandsoft</a> 
                                    (entreprise de génie logiciel, de conception des systèmes intéligents, des applications mobiles et web. 
                                    Nous offrons aussi des solutions d'hébergements. Pour plus d'information, 
                                    <a href="https://legrandsoft.com" target="_blank" rel="noopener noreferrer">cliquez ici pour consulter notre site web</a>). 
                                </p>
                                <img src="/assets/images/logo.jpeg" width="100%" alt="Logo GL Pay - Paiement et tranfert d'argent en ligne un clic." />
                            </div>
                            <div className="col-md-5 wow fadeInDown" data-wow-duration="1000ms" data-wow-delay="600ms">
                                <div className="skill">
                                    <h2>Nos statistiques</h2>
                                    <p>Nous avons reccueilli les avis des personnes et entreprises qui utilisent de notre solution de payement. Les détails sont les suivants</p>
                    
                                    <div className="progress-wrap">
                                        <h4>Ils ont aimez notre solution</h4>
                                        <div className="progress">
                                            <div className="progress-bar color2" role="progressbar" aria-valuenow="99" aria-valuemin="0" aria-valuemax="100" style={{width: "99%"}}>
                                                <span className="bar-width">99%</span>
                                            </div>
                                        </div>
                                    </div>
                    
                                    <div className="progress-wrap">
                                        <h4>Ils nous ont fait des suggessions</h4>
                                        <div className="progress">
                                            <div className="progress-bar color3" role="progressbar" aria-valuenow="5" aria-valuemin="0" aria-valuemax="100" style={{width: "5%"}}>
                                                <span className="bar-width">5%</span>
                                            </div>
                                        </div>
                                    </div>
                    
                                    <div className="progress-wrap">
                                        <h4>Ils nous ont formulés des pleintes</h4>
                                        <div className="progress">
                                            <div className="progress-bar color4" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style={{width: "0.5%"}}>
                                                <span className="bar-width">0%</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="our-team" id="ekip">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="mt-5">Nos modes de paiements acceptés</h2>
                                <hr className="border-secondary" />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="row text-center">
                                <div className="col-md-2 col-sm-3 wow fadeInDown" data-wow-duration="1000ms" data-wow-delay="50ms">
                                    <img src="/assets/images/payements/visa.png" alt="GL Pay - Visa Card" />
                                </div>
                                <div className="col-md-2 col-sm-3 wow fadeInDown" data-wow-duration="1000ms" data-wow-delay="100ms">
                                    <img src="/assets/images/payements/mastercard.jpg" alt="GL Pay - MasterCard" />
                                </div>
                                <div className="col-md-2 col-sm-3 wow fadeInDown" data-wow-duration="1000ms" data-wow-delay="150ms">
                                    <img src="/assets/images/payements/discover.jpeg" alt="GL Pay - Discover Card" />
                                </div>
                                <div className="col-md-2 col-sm-3 wow fadeInDown" data-wow-duration="1000ms" data-wow-delay="200ms">
                                    <img src="/assets/images/payements/amex.jpeg" alt="GL Pay - Amex Card" />
                                </div>
                                <div className="col-md-2 col-sm-3 wow fadeInDown" data-wow-duration="1000ms" data-wow-delay="250ms">
                                    <img src="/assets/images/payements/jcb.jpeg" alt="GL Pay - JCB Card" />
                                </div>
                                <div className="col-md-2 col-sm-3 wow fadeInDown" data-wow-duration="1000ms" data-wow-delay="300ms">
                                    <img src="/assets/images/payements/paypal.png" alt="GL Pay - Paypal" />
                                </div>
                                <div className="col-md-2 col-sm-3 wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="50ms">
                                    <img src="/assets/images/payements/om.png" alt="GL Pay - Orange Money" className="bg-dark p-3" />
                                </div>
                                <div className="col-md-2 col-sm-3 wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="100ms">
                                    <img src="/assets/images/payements/momo.jpg" alt="GL Pay - MTN Mobile Money" />
                                </div>
                                <div className="col-md-2 col-sm-3 wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="150ms">
                                    <img src="/assets/images/payements/airtel-money.png" alt="GL Pay - Airtel Money" />
                                </div>
                                <div className="col-md-2 col-sm-3 wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="200ms">
                                    <img src="/assets/images/payements/malitel-mobicash.png" alt="GL Pay - Malitell MobiCash" />
                                </div>
                                <div className="col-md-2 col-sm-3 wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="250ms">
                                    <img src="/assets/images/payements/tigo-money.png" alt="GL Pay - Tigo Money" />
                                </div>
                                <div className="col-md-2 col-sm-3 wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="300ms">
                                    <img src="/assets/images/payements/wizall-money.png" alt="GL Pay - Wizall Money" />
                                </div>
                                <div className="col-md-2 col-sm-3 wow fadeInDown" data-wow-duration="1000ms" data-wow-delay="50ms">
                                    <img src="/assets/images/payements/moov.jpeg" alt="GL Pay - Moov Money" />
                                </div>
                                <div className="col-md-2 col-sm-3 wow fadeInDown" data-wow-duration="1000ms" data-wow-delay="100ms">
                                    <img src="/assets/images/payements/advans.jpeg" alt="GL Pay - Advans" />
                                </div>
                                <div className="col-md-2 col-sm-3 wow fadeInDown" data-wow-duration="1000ms" data-wow-delay="150ms">
                                    <img src="/assets/images/payements/eu-mobile-money.png" alt="GL Pay - Express Union Mobile Money" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="aboutus" id="polit">
                    <div className="container">
                        <div className="col-md-12">
                            <h2>Nos contacts</h2>
                        </div>
                        <hr />
                        <div className="col-md-7 wow fadeInDown" data-wow-duration="1000ms" data-wow-delay="300ms">
                            <p><i className="fa fa-location"></i> Premier étage, Saint Mark. Carrefour FOE, SOA, Cameroun</p>
                            <p><i className="fa fa-phone"></i> +237 695 955 975</p>
                            <p><i className="fa fa-phone"></i> +237 691 100 161</p>
                        </div>
                        <div className="col-md-5 wow fadeInDown" data-wow-duration="1000ms" data-wow-delay="600ms">
                            <div className="skill">
                                <h2>Contactez-nous</h2>
                                <p>Le formulaire de contact sera disponible au lancement de nos services.</p>
                                <p>Merci de nous faire confiance.</p>
                            </div>
                        </div>
                    </div>
                </div>
                
            </section>
        );
    }
}

export default Apropos;