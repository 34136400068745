import React from 'react';

import TableDisplay from './TableDisplay';

import {Data} from '../../services/data-manager';
import { Modal } from 'react-bootstrap';
import Moment from 'react-moment';

class Factures extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            factures: [],
            page: 1,
            nb: 1,
            size: 50,
            facture: null,
            showDetail: false
        };
        this.getFactures = this.getFactures.bind(this);
        this.getDetails = this.getDetails.bind(this);
        this.showDetailModal = this.showDetailModal.bind(this);
    }
    componentDidMount() {
        this.props.isLoading(true);
        this.getFactures();
    }
    getFactures(page=0) {
        if (page!==0) {
            this.setState({page: page});
        } else {
            page = this.state.page;
        }
        this.props.isLoading(true);
        new Data()
        .get("Facture", "1", "utilisateur,payeur,transactions", "id DESC", page, this.state.size)
        .then(res => {
            res.rows.map(d => { 
                d.nomUtilisateur = d.utilisateur.nom + " " + d.utilisateur.prenom;
                d.nomPayeur = d.payeur.nom + " " + d.payeur.prenom;
            });
            this.setState({factures: res.rows, nb: res.count});
            this.props.isLoading(false);
        })
        .catch(err => {
            console.warn("Error : ", err);
            this.props.isLoading(false);
        });
        window.scrollTo(0,0);
    }
    getDetails(facture) {
        new Data()
        .get("Service","idFacture="+facture.id)
        .then(res => {
            facture.services = res.rows;
        })
        .catch(err => {
            console.log("Error : ", err);
        })
        .finally(() => {
            this.setState({facture: facture}, () => { this.showDetailModal(); });
        });
    }
    showDetailModal(show=true) {
        this.setState({showDetail: show});
    }
    cancelEvenement(facture) {
        window.confirm("Voulez vous vraiment supprimer le paiement de billet "+facture.id);
    }
    render() {
        return (
            <>
                <div className="row">
                    <div className="col-sm-12 my-3">
                        <h3>Factures et paiements [Page {this.state.page}/{(this.state.nb===0)?1:Math.ceil(this.state.nb/this.state.size)}]</h3>
                    </div>
                    <div className="col-sm-12 mb-3">
                        <TableDisplay 
                            tableStriped={true}
                            tableHover={true}
                            thead="dark"
                            titles={["N°", "NOM BENEFICIAIRE", "NOM DESTINATAIRE", "MONTANT", "FRAIS", "A PAYER", "A PERCEVOIR", "", "DATE", "ETAT"]}
                            indices={["numero", "nomUtilisateur", "nomPayeur", "total", "frais", "aPayer", "aPercevoir", "devise", "dateCreation", "etat"]}
                            data={this.state.factures}
                            actions={[
                                {caption: <i className='fa fa-search'></i>, className:"btn btn-sm btn-success mr-1", onClick:this.getDetails},
                                {caption: <i className='fa fa-trash'></i>, className:"btn btn-sm btn-danger", onClick:this.cancelEvenement}
                            ]}
                            withLink="/facture/"
                            withPaging={true}
                            pagingTheme="success"
                            nb={this.state.nb}
                            size={this.state.size}
                            current={this.state.page}
                            onChangePage= {this.getEvenements}
                            withFooter={true} />
                    </div>
                </div>
                {(this.state.facture)?(
                <Modal show={this.state.showDetail} onHide={() => {this.showDetailModal(false)}} className="mt-5">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Détails de la facture #{this.state.facture.numero}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="mx-3">
                            <table className="table table-sm">
                                <tr className="row">
                                    <td className="col-sm-5">Numéro : </td>
                                    <td className="col-sm-7">{this.state.facture.numero}</td>
                                </tr>
                                <tr className="row">
                                    <td className="col-sm-5">Bénéficiaire : </td>
                                    <td className="col-sm-7">{this.state.facture.nomUtilisateur}</td>
                                </tr>
                                <tr className="row">
                                    <td className="col-sm-5">Destinataire : </td>
                                    <td className="col-sm-7">{this.state.facture.nomPayeur}</td>
                                </tr>
                                <tr className="row">
                                    <td className="col-sm-5">Montant : </td>
                                    <td className="col-sm-7">{Intl.NumberFormat().format(this.state.facture.total)} {this.state.facture.devise}</td>
                                </tr>
                                <tr className="row">
                                    <td className="col-sm-5">Frais : </td>
                                    <td className="col-sm-7">{Intl.NumberFormat().format(this.state.facture.frais)} {this.state.facture.devise}</td>
                                </tr>
                                <tr className="row">
                                    <td className="col-sm-5">Qui supporte les frais : </td>
                                    <td className="col-sm-7">{this.state.facture.quiSupporte}</td>
                                </tr>
                                <tr className="row">
                                    <td className="col-sm-5">Montant à payer : </td>
                                    <td className="col-sm-7">{Intl.NumberFormat().format(this.state.facture.aPayer)} {this.state.facture.devise}</td>
                                </tr>
                                <tr className="row">
                                    <td className="col-sm-5">Montant à percevoir : </td>
                                    <td className="col-sm-7">{Intl.NumberFormat().format(this.state.facture.aPercevoir)} {this.state.facture.devise}</td>
                                </tr>
                                <tr className="row">
                                    <td className="col-sm-5">Etat : </td>
                                    <td className="col-sm-7">{this.state.facture.etat}</td>
                                </tr>
                                <tr className="row">
                                    <td className="col-sm-5">Mode de reversion : </td>
                                    <td className="col-sm-7">{this.state.facture.mode}</td>
                                </tr>
                                <tr className="row">
                                    <td className="col-sm-5">Numéro de reversion : </td>
                                    <td className="col-sm-7">{this.state.facture.numeroCompte}</td>
                                </tr>
                                <tr className="row">
                                    <td className="col-sm-5">Date d'enregistrement : </td>
                                    <td className="col-sm-7"><Moment format="DD/MM/YYYY à HH:mm">{this.state.facture.dateCreation}</Moment></td>
                                </tr>
                                <tr className="row">
                                    <td className="col-sm-5">Date de mise à jour : </td>
                                    <td className="col-sm-7"><Moment format="DD/MM/YYYY à HH:mm">{this.state.facture.dateModification}</Moment></td>
                                </tr>
                                <tr className="row">
                                    <td className="col-sm-5">Dévise de destination : </td>
                                    <td className="col-sm-7">{this.state.facture.deviseDestination}</td>
                                </tr>
                                <tr className="row">
                                    <td className="col-sm-5">Taux d'échange : </td>
                                    <td className="col-sm-7">{Intl.NumberFormat().format(this.state.facture.tauxDechange)}</td>
                                </tr>
                                <tr className="row">
                                    <td colspan="2" className="col-sm-12">
                                        <div className="table-pane mt-3">
                                            <table className="table table-bordered table-stiped">
                                                <thead className="thead-dark">
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Type</th>
                                                        <th>Montant</th>
                                                        <th>Etat</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.facture.transactions.map(transaction =>
                                                    <tr>
                                                        <td>{transaction.id}</td>
                                                        <td>{(transaction.type.split("-")[0]==="IN")?'PAIEMENT':''}{(transaction.type.split("-")[0]==="OUT")?'REVERSION':''}</td>
                                                        <td>{Intl.NumberFormat().format(transaction.montant)} {transaction.devise}</td>
                                                        <td>{transaction.status}</td>
                                                    </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </Modal.Body>
                    <Modal.Footer></Modal.Footer>
                </Modal>
                ):''}
            </>
        );
    }
}

export default Factures;