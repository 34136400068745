import React from 'react';

import Notifications from 'react-notify-toast';

import {Modal, Button} from 'react-bootstrap';
import { FlutterWaveButton } from 'flutterwave-react-v3';
import { PayPalButton } from "react-paypal-button-v2";

import {Data} from '../services/data-manager';
import {notifyDanger, notifySuccess, getUUID} from '../utils';
import {typesCartes, modesPaiementMobile, fApiKey, paypalClientID} from '../config/globales';

class Paiement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentYear: new Date().getFullYear(),
            card: {
                type: typesCartes[0].name,
                number: "",
                expire_month: "01",
                expire_year: new Date().getFullYear(),
                cvv2: ""
            },
            mobile: {
                mode: "",
                telephone: ""
            },
            yup: {
              code: "",
              show: false
            },
            statusShow: false,
            btnLoading: false
        };
        this.handleInputCard = this.handleInputCard.bind(this);
        this.handleInputMobile = this.handleInputMobile.bind(this);
        this.handleInputYUP = this.handleInputYUP.bind(this);
        this.getStatus = this.getStatus.bind(this);
        this.statusModalShow = this.statusModalShow.bind(this);
        this.yupModalShow = this.yupModalShow.bind(this);
        this.confirmationYUP = this.confirmationYUP.bind(this);
        this.paiementMobile = this.paiementMobile.bind(this);
        this.paiementCarte = this.paiementCarte.bind(this);
        this.paiementFlutterCallback = this.paiementFlutterCallback.bind(this);
        this.paiementFlutterAnnule = this.paiementFlutterAnnule.bind(this);
        this.paiementPaypalCallback = this.paiementPaypalCallback.bind(this);
        this.btnIsLoadind = this.btnIsLoadind.bind(this);
    }

    btnIsLoadind(isLoading) {
        this.setState({btnLoading: isLoading});
    }

    handleInputCard(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        var card = this.state.card;
        card[name] = value;
        this.setState({card: card});
    }

    handleInputMobile(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        var mobile = this.state.mobile;
        mobile[name] = value;
        this.setState({mobile: mobile});
    }

    handleInputYUP(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        var yup = this.state.yup;
        yup[name] = value;
        this.setState({yup: yup});
    }

    yupModalShow(show) {
      this.setState({yup: {show: show}});
    }
  
    statusModalShow(show) {
      this.setState({statusShow: show});
    }


    paiementMobile(additional=null) {
        /*
        if (this.props.client.montant<500) {
            notifyDanger("Le montant minimal est 500");
            return false;
        }
        */
        if (this.props.client.montant>500000) {
            notifyDanger("Le montant maximal à payer est plafonné à 500 000");
            return false;
        }
        var operateur = this.state.mobile.mode;
        var numero = this.state.mobile.telephone;
        if (operateur==="YUP" && additional===null) {
            new Data().send("/get-yup-code", {
                montant: this.newFacture.aPayer,
                numero: numero
            });
            this.yupModalShow(true);
            return false;
        }
        this.btnIsLoadind(true);
        // On envoi le service et les données pour qu'ils soit enregistrées avec la transaction mobile
        let service = "";
        switch (this.props.parent) {
            case "PaiementDirect":
                service = "PAIEMENT DIRECT";
                break;
            case "PayerFacture":
                service = "PAIEMENT FACTURE";
                break;
            case "PayerEvenement":
                service = "PAIEMENT EVENEMENT";
                break;
        }
        new Data().send("/paiement-mobile", {
            montant: this.props.client.montant,
            operateur: operateur,
            numero: numero,
            additional: additional,
            service: service,
            data: this.props.mobilePayData
        })
        .then(data => {
            data.method = "mobile";
            console.log(data);
            if (data.status) {
                if (data.status!=="FAILED") {
                    this.statusModalShow(true);
                    if (operateur==="YUP" && additional!==null) {
                        data.isYUP = 1;
                        data.numero = numero;
                    }
                    this.getStatus(data);
                }
            }
        })
        .catch(err => {
            notifyDanger("Le paiement à échoué. Veuillez reéssayer !");
            console.warn("Error : ", err);
        })
        .finally(() => {
            this.btnIsLoadind(false);
        });
    }
  
    confirmationYUP() {
        if (this.yup.code==="") {
            notifyDanger("Veuillez entrer le code de confirmation YUP.");
            return false;
        }
        this.yupModalShow(false);
        this.paiementMobile({
            isYUP: true,
            code: this.state.yup.code
        });
    }
    
    getStatus(transaction) {
        var addQString = "";
        if (transaction.isYUP) addQString = "&isYUP=1&numero=" + transaction.numero;
        new Data().send("/payement-mobile-status?idTransaction="+transaction.id+addQString)
        .then(res => {
            if (res.status) {
                if (res.status==="PENDING") {
                    setTimeout(this.getStatus, 5000, transaction);
                } else {
                    this.statusModalShow(false);
                    if (res.status!=="FAILED") {
                        notifySuccess("Transaction effectué avec succès.<br>Le bénéficiaire à déjà reçu le montant du transfert.");
                        /*
                        var data = transaction;
                        data.method = "mobile";
                        switch (this.props.parent) {
                            case "PaiementDirect":
                                //savePaiementDirect(data);
                                this.props.onPay(data);
                                break;
                            case "PayerFacture":
                                //valideFacture(data);
                                this.props.onPay(data);
                                break;
                        }
                        */
                    } else {
                        notifyDanger("Le paiement à échoué !");
                    }
                }
            } else {
                setTimeout(this.getStatus, 5000, transaction);
            }
        })
        .catch(err => {
            notifySuccess("Transaction effectué avec succès.<br>Le bénéficiaire à déjà reçu le montant du transfert.");
            console.warn("Error : ", err);
            setTimeout(this.getStatus, 10000, transaction.idTransaction);
        })
    }

    paiementCarte() {
        new Data().send("/paiement-carte", {
            card: this.state.card,
            client: this.props.client,
            montant: this.props.client.montant,
            devise: this.props.client.devise
        })
        .then(res => {
            console.log(res);
        });
        return false;
    }

    paiementFlutterCallback(data) {
        data.method = "flutter";
        notifySuccess("Votre paiement à été effectué. Le bénéficiaire reçoit l'argent instantanément.");
        switch (this.props.parent) {
            case "PaiementDirect":
                //savePaiementDirect(data);
                this.props.onPay(data);
                break;
            case "PayerFacture":
                //valideFacture(data);
                this.props.onPay(data);
                break;
                break;
            case "PayerEvenement":
                //paiementEvenement(data);
                this.props.onPay(data);
                break;
        }
    }
    paiementFlutterAnnule() {
        //notifyDanger("Paiement annulé.");
    }

    paiementPaypalCallback(details, data) {
        data.method = "paypal";
        data.details = details;
        notifySuccess("Votre paiement à été effectué. Le bénéficiaire reçoit l'argent instantanément.");
        switch (this.props.parent) {
            case "PaiementDirect":
                //savePaiementDirect(data);
                this.props.onPay(data);
                break;
            case "PayerFacture":
                //valideFacture(data);
                this.props.onPay(data);
                break;
            case "PayerEvenement":
                //paiementEvenement(data);
                this.props.onPay(data);
                break;
        }
    }


    render() {
        return (
            <>
                <Notifications options={{top:'49vh'}} />
                <h3>Choisissez votre mode de paiement</h3>
                <hr className="border-secondary" />
                <div className="row">
                    <div className="col-sm-12" id="block-payement">
                        <div className="card card-body">
                            <p className="text-center">
                                <a className="btn btn-primary mb-2 mr-2 ml-auto d-none" href="#collapseCarte" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapseCarte">
                                    <span className="fa fa-credit-card mr-2"></span>
                                    Payer par Carte
                                </a>
                                <FlutterWaveButton 
                                    className="btn btn-secondary mb-2 mr-2 ml-auto mt-0" 
                                    callback={this.paiementFlutterCallback} 
                                    onClose={this.paiementFlutterAnnule} 
                                    {
                                        ...{
                                            public_key: fApiKey,
                                            tx_ref: "bvm_glpay_"+getUUID(),
                                            amount: (this.props.parent==="PayerEvenement")?this.props.client.montant*1.05:this.props.client.montant,
                                            currency: this.props.client.devise,
                                            payment_options: "card,bank",
                                            customer: {
                                                email: this.props.client.email,
                                                phonenumber: this.props.client.telephone,
                                                name: this.props.client.nom + " " + this.props.client.prenom
                                            },
                                            customizations: {
                                                title: "GLPay | Le paiement simplifié en un clic",
                                                description: "Une fois votre paiement effectué, le destinataire reçoit instantanément l'argent.",
                                                logo: "https://glpay.cm/assets/images/logo.jpeg",
                                            }
                                        }
                                    }>
                                    <span className="fa fa-credit-card mr-2"></span>
                                    Payer par Carte Bancaire
                                </FlutterWaveButton>
                                <a className="btn btn-primary mb-2 mr-2" href="#collapsePaypal" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapsePaypal">
                                    <span className="fa fa-paypal mr-2"></span>
                                    Payer par PayPal
                                </a>
                                <a className="btn btn-warning mb-2 mr-auto" href="#collapseMobile" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapseMobile">
                                    <span className="fa fa-mobile fa-2x mr-2" style={{lineHeight: 0, position: 'relative', top: '5px'}}></span>
                                    Payer par Mobile
                                </a>
                            </p>
                            <div className="collapse" id="collapseCarte" data-parent="#block-payement">
                                <div className="form-row mt-3" action="/payement-carte" method="POST" name="CartPaymentForm">
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <select className="form-control border-secondary" name="type" value={this.state.card.type} onChange={this.handleInputCard}>
                                                {( typesCartes.map(tc => <option value={ tc.name } key={'type-carte-'+tc.name}>{ tc.label }</option>) )}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-9">
                                        <div className="form-group">
                                            <input className="form-control border-secondary" type="text" size="19" maxLength="19" name="number" placeholder="XXXX-XXXX-XXXX-XXXX" value={this.state.card.number} onChange={this.handleInputCard} />
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <select className="form-control border-secondary" name="expire_month" value={this.state.card.expire_month} onChange={this.handleInputCard}>
                                                <option value="01">01</option>
                                                <option value="02">02</option>
                                                <option value="03">03</option>
                                                <option value="04">04</option>
                                                <option value="05">05</option>
                                                <option value="06">06</option>
                                                <option value="07">07</option>
                                                <option value="08">08</option>
                                                <option value="09">09</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-5">
                                        <div className="form-group">
                                            <select className="form-control border-secondary" name="expire_year" required value={this.state.card.expire_year} onChange={this.handleInputCard}>
                                                {( [0,1,2,3,4,5,6,7,8,9,10].map(i => <option value={this.state.currentYear+i} key={'expire-year-'+i}>{this.state.currentYear+i}</option>) )}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <input className="form-control border-secondary" type="text" size="3" maxLength="4" name="cvv2" placeholder="CVV" value={this.state.card.cvv2} onChange={this.handleInputCard} />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 text-right">
                                        <button type="button" className="btn btn-info btn-block btn-payer-carte py-3" id="btnPayerCarte" onClick={this.paiementCarte}>Payer {this.props.client.montant} {this.props.client.devise}</button>
                                    </div>
                                </div>
                            </div>
                            <div className="collapse" id="collapsePaypal" data-parent="#block-payement">
                                <PayPalButton 
                                    amount={(this.props.parent==="PayerEvenement")?this.props.client.montant*1.05:this.props.client.montant} 
                                    currency={this.props.client.devise} 
                                    shippingPreference="NO_SHIPPING" 
                                    onSuccess={this.paiementPaypalCallback} 
                                    options={{
                                        clientId: paypalClientID
                                    }}
                                />
                            </div>
                            <div className="collapse" id="collapseMobile" data-parent="#block-payement">
                                <img src="/assets/images/payements/payement-mobile.jpg" alt="VivaNdolo - Payement Mobile" />
                                <div className="momopay" action="/payement-mobile" method="POST">
                                    <select name="mode" id="mode" className="form-control border-secondary mt-2" required onChange={this.handleInputMobile}>
                                        <option value="">Mode de payement mobile sollicité</option>
                                        {( modesPaiementMobile.map(m => <option value={ m.operateur } key={'mode-'+m.operateur}>{ m.name }</option>) )}
                                    </select>
                                    <input type="number" name="telephone" id="telephone" className="form-control border-secondary mt-2" placeholder="Numéro de téléphone ou de Compte" required onChange={this.handleInputMobile} />
                                    <div className="text-right mt-2">
                                        <button type="button" className="btn btn-warning btn-block btn-payer py-3" disabled={this.state.btnLoading} onClick={() => {this.paiementMobile()}}>
                                            {(this.state.btnLoading)?<i className="fa fa-spinner mr-3"></i>:''}
                                            Payer {this.props.client.montant} {this.props.client.devise}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={this.state.yup.show} onHide={() => {this.yupModalShow(false)}}>
                    <Modal.Header closeButton>
                        <Modal.Title>Code YUP</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <label id="code">Code YUP</label>
                            <input type="number" name="code" id="code" value={this.state.yup.code} className="form-control border-secondary" placeholder="Entrer le code de sécurité YUP" onChange={this.handleInputYUP} />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {this.yupModalShow(false)}}>
                            Close
                        </Button>
                        <Button variant="success" onClick={this.confirmationYUP}>
                            Confirmer
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.statusShow} onHide={() => {this.statusModalShow(false)}}>
                    <Modal.Header closeButton>
                        <Modal.Title>Paiement encours</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h3 className="py-5">En attente de validation...</h3>
                    </Modal.Body>
                    <Modal.Footer></Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default Paiement;