import React from 'react';

import TableDisplay from './TableDisplay';

import {Data} from '../../services/data-manager';
import { Modal } from 'react-bootstrap';
import Moment from 'react-moment';

class Transactions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            transactions: [],
            page: 1,
            nbTransaction: 1,
            sizeTransaction: 50,
            transaction: null,
            showDetail: false
        };
        this.getTransactions = this.getTransactions.bind(this);
        this.getDetailTransaction = this.getDetailTransaction.bind(this);
        this.showDetailModal = this.showDetailModal.bind(this);
    }
    componentDidMount() {
        this.props.isLoading(true);
        this.getTransactions();
    }
    getTransactions(page=0) {
        if (page!==0) {
            this.setState({page: page});
        } else {
            page = this.state.page;
        }
        this.props.isLoading(true);
        new Data()
        .get("Transaction", "1", "utilisateur", "dateCreation DESC", page, this.state.sizeTransaction)
        .then(res => {
            this.setState({transactions: res.rows, nbTransaction: res.count});
            this.props.isLoading(false);
        })
        .catch(err => {
            console.warn("Error : ", err);
            this.props.isLoading(false);
        });
        window.scrollTo(0,0);
    }
    getDetailTransaction(transaction) {
        this.setState({transaction: transaction}, () => {
            this.showDetailModal();
        });
    }
    showDetailModal(show=true) {
        this.setState({showDetail: show});
    }
    cancelTransaction(transaction) {
        window.confirm("Voulez vous vraiment supprimer la transaction "+transaction.idTransaction);
    }
    render() {
        return (
            <>
                <div className="row">
                    <div className="col-sm-12 my-3">
                        <h3>Transactions [Page {this.state.page}/{(this.state.nbTransaction===0)?1:Math.ceil(this.state.nbTransaction/this.state.sizeTransaction)}]</h3>
                    </div>
                    <div className="col-sm-12 mb-3">
                        <TableDisplay 
                            tableStriped={true}
                            tableHover={true}
                            thead="dark"
                            titles={["ID", "TYPE", "SERVICE", "MONTANT", "DEVISE", "DATE ET HEURE", "ETAT"]}
                            indices={["id", "type", "service", "montant", "devise", "dateCreation", "status"]}
                            data={this.state.transactions}
                            actions={[
                                {caption: <i className='fa fa-search'></i>, className:"btn btn-sm btn-success mr-1", onClick:this.getDetailTransaction},
                                {caption: <i className='fa fa-trash'></i>, className:"btn btn-sm btn-danger", onClick:this.cancelTransaction}
                            ]}
                            withPaging={true}
                            pagingTheme="success"
                            nb={this.state.nbTransaction}
                            size={this.state.sizeTransaction}
                            current={this.state.page}
                            onChangePage= {this.getTransactions}
                            withFooter={true} />
                    </div>
                </div>
                {(this.state.transaction)?(
                <Modal show={this.state.showDetail} onHide={() => {this.showDetailModal(false)}} className="mt-5">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Détails de la transaction #{this.state.transaction.id}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="mx-3">
                            <table className="table table-sm">
                                <tr className="row">
                                    <td className="col-sm-5">ID : </td>
                                    <td className="col-sm-7">{this.state.transaction.id}</td>
                                </tr>
                                <tr className="row">
                                    <td className="col-sm-5">ID de Traitement : </td>
                                    <td className="col-sm-7">{this.state.transaction.idTransaction}</td>
                                </tr>
                                <tr className="row">
                                    <td className="col-sm-5">ID de Traitement 2 : </td>
                                    <td className="col-sm-7">{this.state.transaction.idTransaction2}</td>
                                </tr>
                                <tr className="row">
                                    <td className="col-sm-5">ID de Traitement 3 : </td>
                                    <td className="col-sm-7">{this.state.transaction.idTransaction3}</td>
                                </tr>
                                <tr className="row">
                                    <td className="col-sm-5">Montant : </td>
                                    <td className="col-sm-7">{Intl.NumberFormat().format(this.state.transaction.montant)} {this.state.transaction.devise}</td>
                                </tr>
                                <tr className="row">
                                    <td className="col-sm-5">Date : </td>
                                    <td className="col-sm-7"><Moment format="DD/MM/YYYY à HH:mm">{this.state.transaction.dateCreation}</Moment></td>
                                </tr>
                                <tr className="row">
                                    <td className="col-sm-5">Etat : </td>
                                    <td className="col-sm-7">{this.state.transaction.status}</td>
                                </tr>
                                <tr className="row">
                                    <td className="col-sm-5">Date de validation : </td>
                                    <td className="col-sm-7"><Moment format="DD/MM/YYYY à HH:mm">{this.state.transaction.dateValidation}</Moment></td>
                                </tr>
                                <tr className="row">
                                    <td className="col-sm-5">Type de transaction : </td>
                                    <td className="col-sm-7">{this.state.transaction.type}</td>
                                </tr>
                                <tr className="row">
                                    <td className="col-sm-5">Service : </td>
                                    <td className="col-sm-7">{this.state.transaction.service}</td>
                                </tr>
                            </table>
                        </div>
                    </Modal.Body>
                    <Modal.Footer></Modal.Footer>
                </Modal>
                ):''}
            </>
        );
    }
}

export default Transactions;