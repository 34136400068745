import React from 'react';

import TableDisplay from './TableDisplay';

import {Data} from '../../services/data-manager';
import { Modal } from 'react-bootstrap';
import Moment from 'react-moment';

class DetailEvenement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            idEvenement: 0,
            evenement: null,
            payerEvenements: [],
            page: 1,
            nb: 1,
            size: 50,
            payerEvenement: null,
            showDetail: false
        };
        this.getEvenement = this.getEvenement.bind(this);
        this.getPayerEvenement = this.getPayerEvenement.bind(this);
        this.getDetailPayerEvenement = this.getDetailPayerEvenement.bind(this);
        this.showDetailModal = this.showDetailModal.bind(this);
    }
    componentDidMount() {
        let t = window.location.pathname.split("/");
        if (t.length==3) {
            this.props.isLoading(true);
            this.setState({idEvenement: t[2]}, () => {
                this.getEvenement();
                this.getPayerEvenement();
            });
        }
    }
    getEvenement() {
        new Data()
        .getOne("Evenement", "id="+this.state.idEvenement, "utilisateur")
        .then(res => {
            this.setState({evenement: res});
        })
        .catch(err => {
            console.warn("Error : ", err);
        });
    }
    getPayerEvenement(page=0) {
        if (page!==0) {
            this.setState({page: page});
        } else {
            page = this.state.page;
        }
        this.props.isLoading(true);
        new Data()
        .get("PayerEvenement", "idEvenement="+this.state.idEvenement, "utilisateur", "id DESC", page, this.state.size)
        .then(res => {
            res.rows.forEach(pe => {
                pe.nom = (pe.utilisateur) ? pe.utilisateur.nom + " " + pe.utilisateur.prenom : "";
                pe.transactions = [];
            });
            this.setState({payerEvenements: res.rows, nb: res.count});
            this.props.isLoading(false);
        })
        .catch(err => {
            console.warn("Error : ", err);
            this.props.isLoading(false);
        });
        window.scrollTo(0,0);
    }
    getDetailPayerEvenement(payerEvenement) {
        new Data()
        .get("Transaction","idPayerEvenement="+payerEvenement.id)
        .then(res => {
            payerEvenement.transactions = res.rows;
        })
        .catch(err => {
            console.log("Error : ", err);
        })
        .finally(() => {
            this.setState({payerEvenement: payerEvenement}, () => { this.showDetailModal(); });
        });
    }
    showDetailModal(show=true) {
        this.setState({showDetail: show});
    }
    cancelPayerEvenement(payerEvenement) {
        window.confirm("Voulez vous vraiment supprimer le paiement de billet "+payerEvenement.id);
    }
    render() {
        return (
            <>
                <div className="row">
                    <div className="col-sm-12 my-3">
                        <h3>{(this.state.evenement)?this.state.evenement.nom:""}</h3>
                        <div>Paiement de billets [Page {this.state.page}/{(this.state.nb===0)?1:Math.ceil(this.state.nb/this.state.size)}]</div>
                        {(this.state.evenement)?<div className="text-secondary">{(this.state.evenement.utilisateur)?this.state.evenement.utilisateur.nom+" "+this.state.evenement.utilisateur.prenom:""}</div>:''}
                    </div>
                    <div className="col-sm-12 mb-3">
                        <TableDisplay 
                            tableStriped={true}
                            tableHover={true}
                            thead="dark"
                            titles={["ID", "NOM", "COUPON", "NB BILLETS", "MONTANT", "DATE ET HEURE", "ETAT"]}
                            indices={["id", "nom", "coupon", "nombrePlace", "montant", "dateCreation", "etat"]}
                            data={this.state.payerEvenements}
                            actions={[
                                {caption: <i className='fa fa-search'></i>, className:"btn btn-sm btn-success mr-1", onClick:this.getDetailPayerEvenement},
                                {caption: <i className='fa fa-trash'></i>, className:"btn btn-sm btn-danger", onClick:this.cancelPayerEvenement}
                            ]}
                            withPaging={true}
                            pagingTheme="success"
                            nb={this.state.nb}
                            size={this.state.size}
                            current={this.state.page}
                            onChangePage= {this.getPayerEvenement}
                            withFooter={true} />
                    </div>
                </div>
                {(this.state.payerEvenement)?(
                <Modal show={this.state.showDetail} onHide={() => {this.showDetailModal(false)}} className="mt-5">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div>Détails du paiement</div>
                            <span style={{fontSize:'14px'}}>{this.state.evenement.nom}</span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-sm-5">Nom : </div>
                            <div className="col-sm-7">{this.state.payerEvenement.utilisateur.nom}</div>
                        </div>
                        <div className="row">
                            <div className="col-sm-5">Pré : </div>
                            <div className="col-sm-7">{this.state.payerEvenement.utilisateur.prenom}</div>
                        </div>
                        <div className="row">
                            <div className="col-sm-5">E-mail : </div>
                            <div className="col-sm-7">{this.state.payerEvenement.utilisateur.email}</div>
                        </div>
                        <div className="row">
                            <div className="col-sm-5">Téléphone : </div>
                            <div className="col-sm-7">{this.state.payerEvenement.utilisateur.telephone}</div>
                        </div>
                        <div className="row">
                            <div className="col-sm-5">Date : </div>
                            <div className="col-sm-7"><Moment format="DD/MM/YYYY à HH:mm">{this.state.payerEvenement.dateCreation}</Moment></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-5">Montant : </div>
                            <div className="col-sm-7">{Intl.NumberFormat().format(this.state.payerEvenement.montant)} {this.state.payerEvenement.devise}</div>
                        </div>
                        <div className="row">
                            <div className="col-sm-5">Etat : </div>
                            <div className="col-sm-7">{this.state.payerEvenement.etat}</div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="table-pane mt-3">
                                    <table className="table table-bordered table-stiped">
                                        <thead className="thead-dark">
                                            <tr>
                                                <th>ID</th>
                                                <th>Montant</th>
                                                <th>Etat</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.payerEvenement.transactions.map(transaction => 
                                            <tr>
                                                <td>{transaction.id}</td>
                                                <td>{transaction.montant} {transaction.devise}</td>
                                                <td>{transaction.status}</td>
                                            </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-5">Coupon : </div>
                            <div className="col-sm-7">{(this.state.payerEvenement.coupon)?this.state.payerEvenement.coupon:<i>Aucun</i>}</div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer></Modal.Footer>
                </Modal>
                ):''}
            </>
        );
    }
}

export default DetailEvenement;