import React from 'react';
import {useParams} from 'react-router-dom';
import Paiement from './Paiement';
import {Data} from '../services/data-manager';
import { notifySuccess } from '../utils';

class PayerEvenement extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            evenements: [],
            evenement: null,
            utilisateur: {
                name: "",
                prenom: "",
                email: "",
                telephone: ""
            },
            payerEvenement: {
                idEvenement: 0,
                nombrePlace: 1,
                montant: 0,
                coupon: ""
            },
            paiementOk: false
        };
        this.listeEvenement = this.listeEvenement.bind(this);
        this.getEvenement = this.getEvenement.bind(this);
        this.paiementEvenement = this.paiementEvenement.bind(this);
        this.loadEvenement = this.loadEvenement.bind(this);
        this.handleInputPayerEvenement = this.handleInputPayerEvenement.bind(this);
        this.handleInputUtilisateur = this.handleInputUtilisateur.bind(this);
    }

    componentDidMount() {
        let id = this.props.idEvenement;
        console.log("ID Facture : "+id);
        if (id>0) {
            this.getEvenement(id);
        }
        this.listeEvenement();
    }

    handleInputUtilisateur(event) {
        const target = event.target;
        var utilisateur = this.state.utilisateur;
        utilisateur[target.name] = target.value;
        this.setState({utilisateur: utilisateur});
    }

    handleInputPayerEvenement(event) {
        const target = event.target;
        var payerEvenement = this.state.payerEvenement;
        payerEvenement[target.name] = target.value;
        if (target.name==="nombrePlace") payerEvenement.montant = payerEvenement.nombrePlace * this.state.evenement.prixBillet;
        this.setState({payerEvenement: payerEvenement});
    }

    listeEvenement() {
        new Data()
        .get("Evenement","1", "utilisateur", "dateCreation DESC")
        .then(res => {
            this.setState({evenements: res.rows});
        })
        .catch(err => {
            console.warn("Error while fetching events.");
        });
    }

    getEvenement(id) {
        new Data()
        .getOne("Evenement","id="+id, "utilisateur")
        .then(res => {
            this.loadEvenement(res);
        })
        .catch(err => {
            console.warn("Error while fetching events.");
        });
    }

    loadEvenement(evenement) {
        if (evenement!==null) {
            let payerEvenement = this.state.payerEvenement;
            payerEvenement.idEvenement = evenement.id;
            payerEvenement.montant = payerEvenement.nombrePlace * evenement.prixBillet;
            this.setState({payerEvenement: payerEvenement});
        }
        new Data()
        .exec(`/PayerEvenement/sum?fields=nombrePlace&where=idEvenement=${evenement.id} AND etat='PAYEE'`)
        .then(res => {
            evenement.nbAchat = res.value;
        })
        .catch(err => {
            console.warn("Error : ", err);
        })
        .finally(() => {
            this.setState({evenement: evenement});
        });
    }

    paiementEvenement(data) {
        let sData = {
            utilisateur: this.state.utilisateur,
            evenement: this.state.evenement,
            aData: data
        };
        new Data().send("/paiement-evenement", sData)
        .then(res => {
            this.setState({paiementOk: true});
            notifySuccess("Echat de billet enregistré.");
        })
        .catch(err => {
            console.warn("Error while recording paiement");
        });
    }

    render() {
        return (
            <section id="payer-facture" className="alert-secondary py-3">
                <div className="container">
                    { (!this.state.evenement)?
                    ( 
                    <div className="my-5 wow fadeInDown text-dark">
                        {this.state.evenements.map(evenement => {
                        return (
                        <div className="row mb-4" key={'evenement-'+evenement.id}>
                            <div className="col-sm-12">
                                <div className="shadow selectible" onClick={() => {this.loadEvenement(evenement);}}>
                                    <div className="row">
                                        <div className="col-sm-12 col-md-4 overflow-hidden">
                                            <img src={(evenement.image)?'https://glpay.legrandsoft.com'+evenement.image:'/assets/images/evenement.jpeg'} className="full-height" />
                                        </div>
                                        <div className="col-sm-12 col-md-8 py-3">
                                            <h2>{evenement.nom}</h2>
                                            <h5 className="text-secondary">{Intl.NumberFormat().format(evenement.prixBillet)} {evenement.devise}</h5>
                                            <div className="text-secondary">
                                                {(+evenement.nombrePlace===-1)?<small>Places illimitées</small>:<small>{Intl.NumberFormat().format(evenement.nombrePlace)} Places</small>}
                                            </div>
                                            <div className="text-wrap">{evenement.description}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> )
                        })}
                    </div>
                    ) :
                    (
                    <div className="my-5 wow fadeInDown text-dark">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="card card-body">
                                    <h3>{ this.state.evenement.nom }</h3>
                                    <h5>Prix du billet : <span>{ Intl.NumberFormat().format(this.state.evenement.prixBillet) } { this.state.evenement.devise }</span></h5>
                                    <div className="text-secondary">
                                        {(+this.state.evenement.nombrePlace===-1)?<small className="mr-1">Places illimitées</small>:<small className="mr-1">{Intl.NumberFormat().format(this.state.evenement.nombrePlace)} Places</small>} | 
                                        {(+this.state.evenement.nbAchat)?<small className="ml-1">{Intl.NumberFormat().format(this.state.evenement.nbAchat)} Places payées</small>:''}
                                    </div>
                                    <div onClick={() => {this.loadEvenement(null);}}>
                                        <span className="text-primary cursor-pointer"><i className="fa fa-arrow-left"></i> Retouner à la liste des évènements</span>
                                    </div>
                                    <hr className="border-secondary" />
                                    <div className="text-black text-wrap">
                                        { this.state.evenement.description }
                                    </div>
                                    <img className="full-width mt-3" src={(this.state.evenement.image)?'https://glpay.legrandsoft.com'+this.state.evenement.image:'/assets/images/evenement.jpeg'} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card card-body">
                                    <h3>Souscription</h3>
                                    <hr className="border-secondary" />
                                    <div className="text-black row mb-3">
                                        <div className="form-group col-sm-12">
                                            <label>Nom</label>
                                            <input className="form-control" name="nom" value={this.state.utilisateur.nom} onChange={this.handleInputUtilisateur} placeholder="Votre Nom" />
                                        </div>
                                        <div className="form-group col-sm-12">
                                            <label>Prénom</label>
                                            <input className="form-control" name="prenom" value={this.state.utilisateur.prenom} onChange={this.handleInputUtilisateur} placeholder="Votre Prénom" />
                                        </div>
                                        <div className="form-group col-sm-12">
                                            <label>Email</label>
                                            <input className="form-control" name="email" value={this.state.utilisateur.email} onChange={this.handleInputUtilisateur} placeholder="Votre adresse e-mail" />
                                        </div>
                                        <div className="form-group col-sm-12">
                                            <label>Téléphone</label>
                                            <input className="form-control" name="telephone" value={this.state.utilisateur.telephone} onChange={this.handleInputUtilisateur} placeholder="Téléphone" />
                                        </div>
                                        <div className="form-group col-sm-6">
                                            <label>Nombre de paces</label>
                                            <input className="form-control" name="nombrePlace" value={this.state.payerEvenement.nombrePlace} onChange={this.handleInputPayerEvenement} placeholder="Nombre de places" />
                                        </div>
                                        <div className="form-group col-sm-6">
                                            <label>Montant</label>
                                            <input className="form-control" name="montant" value={this.state.payerEvenement.montant} onChange={this.handleInputPayerEvenement} placeholder="Montant" readOnly />
                                        </div>
                                        <div className="form-group col-sm-12">
                                            <label>Coupon</label>
                                            <input className="form-control" name="coupon" value={this.state.payerEvenement.coupon} onChange={this.handleInputPayerEvenement} placeholder="Entré votre coupon ici" />
                                        </div>
                                    </div>
                                    { (!this.state.paiementOk) ? (
                                    <Paiement onPay={this.paiementEvenement} parent="PayerEvenement" client={{
                                            nom: this.state.utilisateur.nom,
                                            prenom: this.state.utilisateur.prenom,
                                            email: this.state.utilisateur.email,
                                            telephone: this.state.utilisateur.telephone,
                                            montant: this.state.payerEvenement.montant,
                                            devise: this.state.evenement.devise
                                        }} 
                                        mobilePayData={{
                                            utilisateur: this.state.utilisateur,
                                            payerEvenement: this.state.payerEvenement
                                        }} 
                                    />
                                    ) : (
                                    <>
                                        <h3 className="py-5 text-success font-weight-bold">Billet de participation payé</h3>
                                        <a href="#" className="py-5 text-success font-weight-bold" onClick={(e) => {e.preventDefault(); this.setState({paiementOk: false});}}>Paiyer encore un billet</a>
                                    </>
                                    )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    )}
                </div>
            </section>
        );
    }
}

export default PayerEvenement;