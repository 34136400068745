import React from 'react';

import TableDisplay from '../admin/TableDisplay';

import {Data} from '../../services/data-manager';
import { Modal } from 'react-bootstrap';
import Moment from 'react-moment';

export default class PaiementMarchand extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            transactions: [],
            page: 1,
            nbTransaction: 1,
            sizeTransaction: 50,
            transaction: null,
            showDetail: false
        };
        this.getTransactions = this.getTransactions.bind(this);
        this.getDetailTransaction = this.getDetailTransaction.bind(this);
        this.showDetailModal = this.showDetailModal.bind(this);
    }
    componentDidMount() {
        this.props.isLoading(true);
        this.getTransactions();
    }
    getTransactions(page=0) {
        if (page!==0) {
            this.setState({page: page});
        } else {
            page = this.state.page;
        }
        this.props.isLoading(true);
        new Data()
        .get("PaiementMarchand", `idMarchand='${this.props.marchands[0].id}'`, "transactionSource,transactionDestination,portailMarchand", "dateCreation DESC", page, this.state.sizeTransaction)
        .then(res => {
            this.setState({transactions: res.rows, nbTransaction: res.count});
            this.props.isLoading(false);
        })
        .catch(err => {
            console.warn("Error : ", err);
            this.props.isLoading(false);
        });
        window.scrollTo(0,0);
    }
    getDetailTransaction(transaction) {
        this.setState({transaction: transaction}, () => {
            this.showDetailModal();
        });
    }
    showDetailModal(show=true) {
        this.setState({showDetail: show});
    }
    render() {
        return (
            <>
                <div className="row">
                    <div className="col-sm-12 my-3">
                        <h3>Transactions [Page {this.state.page}/{(this.state.nbTransaction===0)?1:Math.ceil(this.state.nbTransaction/this.state.sizeTransaction)}]</h3>
                    </div>
                    <div className="col-sm-12 mb-3">
                        <TableDisplay 
                            tableStriped={true}
                            tableHover={true}
                            thead="dark"
                            titles={["ID", "MONTANT", "VALEUR", "ETAT", "DATE ET HEURE"]}
                            indices={["id", "montant", "aPercevoir", "status", "dateCreation"]}
                            data={this.state.transactions}
                            actions={[
                                {caption: <i className='fa fa-list'></i>, className:"btn btn-sm btn-success mr-1", onClick:this.getDetailTransaction}
                            ]}
                            withPaging={true}
                            pagingTheme="success"
                            nb={this.state.nbTransaction}
                            size={this.state.sizeTransaction}
                            current={this.state.page}
                            onChangePage= {this.getTransactions}
                            withFooter={true} />
                    </div>
                </div>
                {(this.state.transaction)?(
                <Modal show={this.state.showDetail} onHide={() => {this.showDetailModal(false)}} className="mt-5">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Détails de la transaction #{this.state.transaction.id}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="mx-3">
                            <table className="table table-sm">
                                <tr className="row">
                                    <td className="col-sm-5">ID : </td>
                                    <td className="col-sm-7">{this.state.transaction.id}</td>
                                </tr>
                                <tr className="row">
                                    <td className="col-sm-5">Code Marchand : </td>
                                    <td className="col-sm-7">{this.state.transaction.codeMarchand}</td>
                                </tr>
                                {this.state.transaction.portailMarchand &&
                                <tr className="row">
                                    <td className="col-sm-5">Nom du client : </td>
                                    <td className="col-sm-7">{this.state.transaction.portailMarchand.nomClient} {this.state.transaction.portailMarchand.prenomClient}</td>
                                </tr>}
                                {this.state.transaction.portailMarchand &&
                                <tr className="row">
                                    <td className="col-sm-5">Email du client : </td>
                                    <td className="col-sm-7">{this.state.transaction.portailMarchand.emailClient}</td>
                                </tr>}
                                {this.state.transaction.portailMarchand &&
                                <tr className="row">
                                    <td className="col-sm-5">Téléphone du client : </td>
                                    <td className="col-sm-7">{this.state.transaction.portailMarchand.telephone}</td>
                                </tr>}
                                <tr className="row">
                                    <td className="col-sm-5">Montant : </td>
                                    <td className="col-sm-7">{Intl.NumberFormat().format(this.state.transaction.montant)} {this.state.transaction.devise}</td>
                                </tr>
                                <tr className="row">
                                    <td className="col-sm-5">Date : </td>
                                    <td className="col-sm-7"><Moment format="DD/MM/YYYY à HH:mm">{this.state.transaction.dateCreation}</Moment></td>
                                </tr>
                                <tr className="row">
                                    <td className="col-sm-5">Etat : </td>
                                    <td className="col-sm-7">{this.state.transaction.status}</td>
                                </tr>
                                <tr className="row">
                                    <td className="col-sm-5">Date de validation : </td>
                                    <td className="col-sm-7"><Moment format="DD/MM/YYYY à HH:mm">{this.state.transaction.datePaiement}</Moment></td>
                                </tr>
                                <tr className="row">
                                    <td colspan="2" className="col-sm-12">
                                        <div className="table-pane mt-3">
                                            <table className="table table-bordered table-stiped">
                                                <thead className="thead-dark">
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Type</th>
                                                        <th>Montant</th>
                                                        <th>Etat</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(this.state.transaction.transactionSource)?
                                                    <tr>
                                                        <td>{this.state.transaction.transactionSource.id}</td>
                                                        <td>Source</td>
                                                        <td>{Intl.NumberFormat().format(this.state.transaction.transactionSource.montant)} {this.state.transaction.transactionSource.devise}</td>
                                                        <td>{this.state.transaction.transactionSource.status}</td>
                                                    </tr>
                                                    :''}
                                                    {(this.state.transaction.transactionDestination)?
                                                    <tr>
                                                        <td>{this.state.transaction.transactionDestination.id}</td>
                                                        <td>Destination</td>
                                                        <td>{Intl.NumberFormat().format(this.state.transaction.transactionDestination.montant)} {this.state.transaction.transactionDestination.devise}</td>
                                                        <td>{this.state.transaction.transactionDestination.status}</td>
                                                    </tr>
                                                    :''}
                                                </tbody>
                                            </table>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </Modal.Body>
                    <Modal.Footer></Modal.Footer>
                </Modal>
                ):''}
            </>
        );
    }
}