import React from 'react';
import {useParams} from 'react-router-dom';
import Paiement from './Paiement';
import {Data} from '../services/data-manager';
import { notifySuccess } from '../utils';

class PayerFacture extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            idFacture: "",
            searchFacture: {
				utilisateur: {},
				payeur: {
                    name: "",
                    prenom: "",
                    email: "",
                    telephone: ""
                },
				services: []
			}
        };
        this.researchFacture = this.researchFacture.bind(this);
        this.getFacture = this.getFacture.bind(this);
        this.valideFacture = this.valideFacture.bind(this);
        this.handleInputPayeur = this.handleInputPayeur.bind(this);
    }

    componentDidMount() {
        let idFacture = this.props.idFacture;
        console.log("ID Facture : "+idFacture);
        if (idFacture.length>0) {
            this.setState({idFacture: idFacture}, this.getFacture);
        }
    }
    
    handleInputPayeur(event) {
        const target = event.target;
        var searchFacture = this.state.searchFacture;
        if (target.type==="checkbox") {
            if (target.checked) {
                searchFacture.payeur[target.name] = target.hasAttribute("true-value")?target.getAttribute("true-value"):target.checked;
            } else {
                searchFacture.payeur[target.name] = target.hasAttribute("false-value")?target.getAttribute("false-value"):target.checked;
            }
        } else {
            searchFacture.payeur[target.name] = target.value;
        }
        this.setState({searchFacture: searchFacture});
    }

    researchFacture(e) {
        e.preventDefault();
        if (this.state.idFacture.length>0) {
            this.getFacture();
        }
    }

    getFacture() {
        new Data().getOne("Facture", `id='${this.state.idFacture}'`, "utilisateur,payeur,services")
        .then(facture => {
            if (facture) {
                this.setState({searchFacture: facture});
            }
        });
    }

    valideFacture(data) {
        console.log("Paiement effectué : ", data);
        var facture = this.state.searchFacture;
        facture.aData = data;
        new Data().send("/valide-facture", facture)
        .then(res => {
            notifySuccess("Facture validé avec succès. Le bénéficiaire reçoit instantanément le paiement.");
            console.log(res);
            facture.dateModification = new Date().toLocaleString();
            this.setState({searchFacture: facture});
        });
    }

    render() {
        return (
            <section id="payer-facture" className="alert-secondary py-3">
                <div className="container">
                    <form className="mb-3" action="/payer-facture" onSubmit={this.researchFacture}>
                        <div className="input-group">
                            <input type="text" className="form-control form-control search_box" autoComplete="off" placeholder="Entrer le numéro de votre facture ou du point marchant" value={this.state.idFacture} onChange={(e) => {this.setState({idFacture: e.target.value})}} />
                            <div className="input-group-prepend">
                                <button type="submit" className="input-group-text btn-success mt-0">RECHERCHER</button>
                            </div>
                        </div>
                    </form>
                    
                    { (!this.state.searchFacture.id)?
                    ( <div className="vspace-30"></div> ) :
                    (
                    <div className="my-5 wow fadeInDown text-dark">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="card card-body">
                                    <h3>Facture N°{ this.state.searchFacture.numero }</h3>
                                    <hr className="border-secondary" />
                                    <div className="row my-0">
                                        <div className="col-12">Nom : <span className="font-weight-bold">{ this.state.searchFacture.utilisateur.nom }</span></div>
                                    </div>
                                    <div className="row my-0">
                                        <div className="col-12">Prénom : <span className="font-weight-bold">{ this.state.searchFacture.utilisateur.prenom }</span></div>
                                    </div>
                                    <div className="row my-0">
                                        <div className="col-12">Pays : <span className="font-weight-bold">{ this.state.searchFacture.utilisateur.idPays }</span></div>
                                    </div>
                                    <div className="row my-0">
                                        <div className="col-12">Ville : <span className="font-weight-bold">{ this.state.searchFacture.utilisateur.ville }</span></div>
                                    </div>
                                    <div className="row my-0">
                                        <div className="col-12">Adresse : <span className="font-weight-bold">{ this.state.searchFacture.utilisateur.adresse }</span></div>
                                    </div>
                                    <div className="row my-0">
                                        <div className="col-12">Téléphone : <span className="font-weight-bold">{ this.state.searchFacture.utilisateur.idPays }{ this.state.searchFacture.utilisateur.telephone }</span></div>
                                    </div>
                                    <div className="row my-0">
                                        <div className="col-12">E-mail : <span className="font-weight-bold">{ this.state.searchFacture.utilisateur.email }</span></div>
                                    </div>

                                    <div className="row mb-0 mt-2 text-right">
                                        <div className="col-12 font-weight-bold">{ this.state.searchFacture.payeur.nomUtilisateur }</div>
                                        <div className="col-12 font-weight-bold">{ this.state.searchFacture.payeur.email }</div>
                                        <div className="col-12 font-weight-bold">{ this.state.searchFacture.payeur.telephone }</div>
                                    </div>

                                    <div className="row my-5">
                                        <div className="col-sm-12 px-5">
                                            <div className="row font-weight-bold text-light bg-secondary">
                                                <div className="col-sm-6">Service / Produit</div>
                                                <div className="col-sm-2 text-center">Qté</div>
                                                <div className="col-sm-4 text-right">Montant</div>
                                            </div>
                                            {( this.state.searchFacture.services.map( service =>
                                            <div className="row border-bottom border-secondary" key={'service-'+service.id}>
                                                <div className="col-sm-6">{ service.nom }</div>
                                                <div className="col-sm-2 text-center">{ service.quantite }</div>
                                                <div className="col-sm-4 text-right">{ service.montant }</div>
                                            </div>
                                            ) )}
                                            <div className="row border-bottom border-top border-secondary">
                                                <div className="col-sm-8">Montant total</div>
                                                <div className="col-sm-4 text-right">{ this.state.searchFacture.total }</div>
                                            </div>
                                            <div className="row border-bottom border-secondary">
                                                <div className="col-sm-8">Frais de transaction</div>
                                                <div className="col-sm-4 text-right">{ (this.state.searchFacture.quiSupporte==="Payeur")? this.state.searchFacture.frais:0 }</div>
                                            </div>
                                            <div className="row border-bottom border-top border-secondary font-weight-bold">
                                                <div className="col-sm-8">Montant net à payer</div>
                                                <div className="col-sm-4 text-right">{ +this.state.searchFacture.total + ((this.state.searchFacture.quiSupporte==="Payeur")? +this.state.searchFacture.frais:0) }</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card card-body">
                                    <h3>Expéditeur (Payeur)</h3>
                                    <hr className="border-secondary" />
                                    <div className="text-black row">
                                        <div className="form-group col-sm-12">
                                            <label>Nom</label>
                                            <input className="form-control" name="nom" value={this.state.searchFacture.payeur.nom} onChange={this.handleInputUtilisateur} placeholder="Votre Nom" />
                                        </div>
                                        <div className="form-group col-sm-12">
                                            <label>Prénom</label>
                                            <input className="form-control" name="prenom" value={this.state.searchFacture.payeur.prenom} onChange={this.handleInputUtilisateur} placeholder="Votre Prénom" />
                                        </div>
                                        <div className="form-group col-sm-12">
                                            <label>Email</label>
                                            <input className="form-control" name="email" value={this.state.searchFacture.payeur.email} onChange={this.handleInputUtilisateur} placeholder="Votre adresse e-mail" />
                                        </div>
                                        <div className="form-group col-sm-12">
                                            <label>Téléphone</label>
                                            <input className="form-control" name="telephone" value={this.state.searchFacture.payeur.telephone} onChange={this.handleInputUtilisateur} placeholder="Téléphone" />
                                        </div>
                                    </div>
                                    { (!this.state.searchFacture.dateModification) ? (
                                    <Paiement onPay={this.valideFacture} parent="PayerFacture" client={{
                                            nom: this.state.searchFacture.payeur.nom,
                                            prenom: this.state.searchFacture.payeur.prenom,
                                            email: this.state.searchFacture.payeur.email,
                                            telephone: this.state.searchFacture.payeur.telephone,
                                            montant: this.state.searchFacture.aPayer,
                                            devise: this.state.searchFacture.devise
                                        }} 
                                        mobilePayData={{
                                            facture: this.state.searchFacture
                                        }} 
                                    />
                                    ) : (
                                    <h3 className="py-5 text-success font-weight-bold">FACTURE PAYER</h3>
                                    )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    ) }
                </div>
            </section>
        );
    }
}

export default PayerFacture;