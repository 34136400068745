import React from 'react';
import {Chart} from 'react-charts';
import moment from 'moment';
import { Data } from '../../services/data-manager';

export default class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            weeklyData: [],
            weeklyAxes: [
                { primary: true, type: 'linear', position: 'bottom' },
                { type: 'linear', position: 'left' },
                { type: 'linear', position: 'right' }
            ]
        }
        this.getWeeklyData = this.getWeeklyData.bind(this);
    }
    componentDidMount() {
        this.getWeeklyData();
    }
    async getWeeklyData() {
        let data = [
            {
                label: 'Factures',
                data: []
            },
            {
                label: 'Mobile vers mobile',
                data: []
            },
            {
                label: 'Evènement',
                data: []
            }
        ];
        var value, sDate, momt, sWeek;
        for (let i=0; i<7; i++) {
            momt = moment().add(-i, 'days');
            sDate = momt.format("YYYY-MM-DD");
            sWeek = momt.format("dddd").split()[0].toUpperCase();
            // Les données de factures
            value = 0;
            await new Data()
            .exec(`/Facture/sum?fields=total&where=dateCreation LIKE '${sDate}%' AND etat='PAYEE'`)
            .then(res => {
                if (res.value) value = res.value;
            })
            .catch(err => {
                console.warn("Error : ", err);
            })
            .finally(() => {
                data[0].data.push([i+1, value]);
            });
            // Les données du mobile vers mobile
            value = 0;
            await new Data()
            .exec(`/MomoVersMomo/sum?fields=montant&where=dateCreation LIKE '${sDate}%' AND status='PAYEE'`)
            .then(res => {
                if (res.value) value = res.value;
            })
            .catch(err => {
                console.warn("Error : ", err);
            })
            .finally(() => {
                data[1].data.push([i+1, value]);
            });
            // Les données des évènements
            value = 0;
            await new Data()
            .exec(`/PayerEvenement/sum?fields=montant&where=dateCreation LIKE '${sDate}%' AND etat='PAYEE'`)
            .then(res => {
                if (res.value) value = res.value;
            })
            .catch(err => {
                console.warn("Error : ", err);
            })
            .finally(() => {
                data[2].data.push([i+1, value]);
            });
        }
        this.setState({
            weeklyData: data
        });
    }
    render() {
        return (
            <div className="row">
                <div className="col-md-12 mt-5 mbt-3 text-center">
                    <h2>Bienvenu dans l'espace d'administration de GLPay</h2>
                </div>
                <div className="col-md-6">
                    <div style={{width:'100%', height: '240px'}}>
                        <Chart data={this.state.weeklyData} axes={this.state.weeklyAxes} getLabel="Statistique des transactions" />
                    </div>
                </div>
                <div className="col-md-6">
                    <div style={{width:'100%', height: '240px'}}>
                        <Chart data={this.state.weeklyData} axes={this.state.weeklyAxes} />
                    </div>
                </div>
                <div className="col-md-12"></div>
            </div>
        );
    }
}