import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';

import './App.css';

import {Header, Footer, Breadcrumb, Loading, StatusModal, YUPModal} from './components/Body';
import Accueil from './components/Accueil';
import Apropos from './components/Apropos';
import CreerFacture from './components/CreerFacture';
import MobileVersMobile from './components/MobileVersMobile';
import PaiementDirect from './components/PaiementDirect';
import PayerFacture from './components/PayerFacture';
import PayerEvenement from './components/PayerEvenement';

import {routes} from './config/globales';

import {Data} from './services/data-manager';
import {notifySuccess, notifyDanger} from './utils';
import Paiement from './components/Paiement';


class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    this.setState({loading: false});
  }

  animationTraitement(isAnimated) {
    this.setState({loading: isAnimated});
  }


  render() {
    return (
      <Router>
        <Loading show={this.state.loading} />
        <Header />
        <Switch>
          <Route exact path="/">
            <Accueil isLoading={this.animationTraitement} />
          </Route>
          <Route path="/creer-facture">
            <Breadcrumb text="Créer une facture" />
            <CreerFacture isLoading={this.animationTraitement} />
          </Route>
          <Route path="/payer-facture">
            <Breadcrumb text="Payer une facture" />
            <PayerFacture isLoading={this.animationTraitement} idFacture={0} />
          </Route>
          <Route path="/payer-facture/:idFacture" render={
            (props) => <>
              <Breadcrumb text="Payer une facture" />
              <PayerFacture isLoading={this.animationTraitement} idFacture={props.match.params.idFacture} />
            </>
          } />
          <Route path="/paiement-direct">
            <Breadcrumb text="Paiement direct" />
            <PaiementDirect isLoading={this.animationTraitement} />
          </Route>
          <Route path="/mobile-vers-mobile">
            <Breadcrumb text="Mobile vers mobile" />
            <MobileVersMobile isLoading={this.animationTraitement} />
          </Route>
          <Route path="/payer-evenement">
            <Breadcrumb text="Payer un Evènement" />
            <PayerEvenement isLoading={this.animationTraitement} />
          </Route>
          <Route path="/payer-evenement/:idEvenement" render={
            (props) => <>
              <Breadcrumb text="Payer un Evènement" />
              <PayerFacture isLoading={this.animationTraitement} idEvenement={props.match.params.idEvenement} />
            </>
          } />
          <Route path="/apropos">
            <Breadcrumb text="A propos" />
            <Apropos isLoading={this.animationTraitement} />
          </Route>
        </Switch>
        <Footer />
      </Router>
    );
  }

}

export default App;