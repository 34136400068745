import React from 'react';

import TableDisplay from './TableDisplay';

import {Data} from '../../services/data-manager';
import { Modal } from 'react-bootstrap';
import Moment from 'react-moment';

class MobileVersMobile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mvms: [],
            page: 1,
            nb: 1,
            size: 50,
            mvm: null,
            showDetail: false
        };
        this.getMobileVersMobile = this.getMobileVersMobile.bind(this);
        this.getDetails = this.getDetails.bind(this);
        this.showDetailModal = this.showDetailModal.bind(this);
    }
    componentDidMount() {
        this.props.isLoading(true);
        this.getMobileVersMobile();
    }
    getMobileVersMobile(page=0) {
        if (page!==0) {
            this.setState({page: page});
        } else {
            page = this.state.page;
        }
        this.props.isLoading(true);
        new Data()
        .get("MomoVersMomo", "1", "transactionSource,transactionDestination", "id DESC", page, this.state.size)
        .then(res => {
            res.rows.map(d => { d.dateCreation = d.transactionSource.dateCreation; });
            this.setState({mvms: res.rows, nb: res.count});
            this.props.isLoading(false);
        })
        .catch(err => {
            console.warn("Error : ", err);
            this.props.isLoading(false);
        });
        window.scrollTo(0,0);
    }
    getDetails(mvm) {
        this.setState({mvm: mvm}, () => { this.showDetailModal(); });
    }
    showDetailModal(show=true) {
        this.setState({showDetail: show});
    }
    cancelEvenement(mvm) {
        window.confirm("Voulez vous vraiment supprimer le paiement de billet "+mvm.id);
    }
    render() {
        return (
            <>
                <div className="row">
                    <div className="col-sm-12 my-3">
                        <h3>Transactions de mobile vers mobile [Page {this.state.page}/{(this.state.nb===0)?1:Math.ceil(this.state.nb/this.state.size)}]</h3>
                    </div>
                    <div className="col-sm-12 mb-3">
                        <TableDisplay 
                            tableStriped={true}
                            tableHover={true}
                            thead="dark"
                            titles={["ID", "SOURCE", "NUMERO", "ETAT", "DESTINATION", "NUMERO", "ETAT", "MONTANT", "", "DATE", "ETAT ACTUEL"]}
                            indices={["id", "operateurSource", "numeroSource", "statusSource", "operateurDestination", "numeroDestination", "statusDestination", "montant", "deviseSource", "dateCreation", "status"]}
                            data={this.state.mvms}
                            actions={[
                                {caption: <i className='fa fa-search'></i>, className:"btn btn-sm btn-success mr-1", onClick:this.getDetails},
                                {caption: <i className='fa fa-trash'></i>, className:"btn btn-sm btn-danger", onClick:this.cancelEvenement}
                            ]}
                            withLink="/mvm/"
                            withPaging={true}
                            pagingTheme="success"
                            nb={this.state.nb}
                            size={this.state.size}
                            current={this.state.page}
                            onChangePage= {this.getEvenements}
                            withFooter={true} />
                    </div>
                </div>
                {(this.state.mvm)?(
                <Modal show={this.state.showDetail} onHide={() => {this.showDetailModal(false)}} className="mt-5">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Détails du transfert mobile vers mobile #{this.state.mvm.id}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="mx-3">
                            <table className="table table-sm">
                                <tr className="row">
                                    <td className="col-sm-5">ID : </td>
                                    <td className="col-sm-7">{this.state.mvm.id}</td>
                                </tr>
                                <tr className="row">
                                    <td className="col-sm-5">Opérateur source : </td>
                                    <td className="col-sm-7">{this.state.mvm.operateurSource}</td>
                                </tr>
                                <tr className="row">
                                    <td className="col-sm-5">Numéro source : </td>
                                    <td className="col-sm-7">{this.state.mvm.numeroSource}</td>
                                </tr>
                                <tr className="row">
                                    <td className="col-sm-5">Status source : </td>
                                    <td className="col-sm-7">{this.state.mvm.statusSource}</td>
                                </tr>
                                <tr className="row">
                                    <td className="col-sm-5">Opérateur de destination : </td>
                                    <td className="col-sm-7">{this.state.mvm.operateurDestination}</td>
                                </tr>
                                <tr className="row">
                                    <td className="col-sm-5">Numéro de destination : </td>
                                    <td className="col-sm-7">{this.state.mvm.numeroDestination}</td>
                                </tr>
                                <tr className="row">
                                    <td className="col-sm-5">Status de destination : </td>
                                    <td className="col-sm-7">{this.state.mvm.statusDestination}</td>
                                </tr>
                                <tr className="row">
                                    <td className="col-sm-5">Montant : </td>
                                    <td className="col-sm-7">{Intl.NumberFormat().format(this.state.mvm.montant)} {this.state.mvm.deviseSource}</td>
                                </tr>
                                <tr className="row">
                                    <td className="col-sm-5">Frais : </td>
                                    <td className="col-sm-7">{Intl.NumberFormat().format(this.state.mvm.frais)} {this.state.mvm.deviseSource}</td>
                                </tr>
                                <tr className="row">
                                    <td className="col-sm-5">Dévise de destination : </td>
                                    <td className="col-sm-7">{this.state.mvm.deviseDestination}</td>
                                </tr>
                                <tr className="row">
                                    <td className="col-sm-5">Etat : </td>
                                    <td className="col-sm-7">{this.state.mvm.status}</td>
                                </tr>
                                <tr className="row">
                                    <td className="col-sm-5">Date : </td>
                                    <td className="col-sm-7"><Moment format="DD/MM/YYYY à HH:mm">{this.state.mvm.transactionSource.dateCreation}</Moment></td>
                                </tr>
                                <tr className="row">
                                    <td colspan="2" className="col-sm-12">
                                        <div className="table-pane mt-3">
                                            <table className="table table-bordered table-stiped">
                                                <thead className="thead-dark">
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Type</th>
                                                        <th>Montant</th>
                                                        <th>Etat</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(this.state.mvm.transactionSource)?
                                                    <tr>
                                                        <td>{this.state.mvm.transactionSource.id}</td>
                                                        <td>Source</td>
                                                        <td>{Intl.NumberFormat().format(this.state.mvm.transactionSource.montant)} {this.state.mvm.transactionSource.devise}</td>
                                                        <td>{this.state.mvm.transactionSource.status}</td>
                                                    </tr>
                                                    :''}
                                                    {(this.state.mvm.transactionDestination)?
                                                    <tr>
                                                        <td>{this.state.mvm.transactionDestination.id}</td>
                                                        <td>Destination</td>
                                                        <td>{Intl.NumberFormat().format(this.state.mvm.transactionDestination.montant)} {this.state.mvm.transactionDestination.devise}</td>
                                                        <td>{this.state.mvm.transactionDestination.status}</td>
                                                    </tr>
                                                    :''}
                                                </tbody>
                                            </table>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </Modal.Body>
                    <Modal.Footer></Modal.Footer>
                </Modal>
                ):''}
            </>
        );
    }
}

export default MobileVersMobile;