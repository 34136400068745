/**
 * global variables
 */
const ENV = "default"; //"marchand"; //"admin"; //
const TAUX_BANK = 5;
var routes = [];
switch (ENV) {
    case "admin":
        routes = [
            {
                path: "/",
                name: "dashboard",
                label: "Accueil",
                inMenu: true
            },
            {
                path: "/mobile-vers-mobile",
                name: "mobile-vers-mobile",
                label: "Mobile vers mobile",
                inMenu: true
            },
            {
                path: "/factures",
                name: "factures",
                label: "Factures",
                inMenu: true
            },
            {
                path: "/evenements",
                name: "evenements",
                label: "Evenements",
                inMenu: true
            },
            {
                path: "/transactions",
                name: "transactions",
                label: "Transactions",
                inMenu: true
            }
        ];
        break;
    case "marchand":
        routes = [
            {
                path: "/",
                name: "dashboard",
                label: "Accueil",
                inMenu: true
            },
            {
                path: "/transactions",
                name: "paiement-marchands",
                label: "Paiements",
                inMenu: true
            }
        ];
        break;
    default :
        routes = [
            {
                path: "/",
                name: "accueil",
                label: "Accueil",
                inMenu: true
            },
            {
                path: "/creer-facture",
                name: "creer-facture",
                label: "Créer une facture",
                inMenu: true
            },
            {
                path: "/payer-facture",
                name: "payer-facture",
                label: "Payer une facture",
                inMenu: true
            },
            {
                path: "/payer-facture/:idFacture",
                name: "payer-facture-connue",
                label: "Payer une facture",
                inMenu: false
            },
            {
                path: "/paiement-direct",
                name: "paiement-direct",
                label: "Paiement direct",
                inMenu: true
            },
            {
                path: "/mobile-vers-mobile",
                name: "mobile-vers-mobile",
                label: "Mobile vers mobile",
                inMenu: true
            },
            {
                path: "/payer-evenement",
                name: "payer-evenement",
                label: "Evènement et Billet",
                inMenu: true
            },
            {
                path: "/apropos",
                name: "apropos",
                label: "A propos",
                inMenu: true
            }
        ];
}

const devisesAcceptes = [
    {nom: "XAF", valeur: 1, reel: 1.00},
    {nom: "XOF", valeur: 1, reel: 1.00},
    {nom: "KES", valeur: 6, reel: 5.11},
    {nom: "AUD", valeur: 403},
    {nom: "BRL", valeur: 110},
    {nom: "CAD", valeur: 429},
    {nom: "CZK", valeur: 25},
    {nom: "DKK", valeur: 89},
    {nom: "EUR", valeur: 659, reel: 557.11},
    {nom: "HKD", valeur: 76},
    {nom: "HUF", valeur: 2},
    {nom: "INR", valeur: 8},
    {nom: "ILS", valeur: 171},
    {nom: "JPY", valeur: 6},
    {nom: "MYR", valeur: 137},
    {nom: "MXN", valeur: 26},
    {nom: "TWD", valeur: 20},
    {nom: "NZD", valeur: 378},
    {nom: "NOK", valeur: 61},
    {nom: "PHP", valeur: 12},
    {nom: "PLN", valeur: 148},
    {nom: "GBP", valeur: 726},
    {nom: "RUB", valeur: 9},
    {nom: "SGD", valeur: 421},
    {nom: "SEK", valeur: 63},
    {nom: "CHF", valeur: 618},
    {nom: "THB", valeur: 19},
    {nom: "USD", valeur: 555, reel: 553.91}
];

function montantDansMinMax(montant, devise, min=500, max=500000) {
    for (let i = 0; i < devisesAcceptes.length; i++) {
        if (devisesAcceptes[i].nom==devise) {
            if (montant*devisesAcceptes[i].valeur>=min && montant*devisesAcceptes[i].valeur<=max) return true;
            else return [Math.round(min/devisesAcceptes[i].valeur), Math.round(max/devisesAcceptes[i].valeur)];
        }
    }
    return false;
}
function montantMax(codePays="CM") {
    if (codePays=="CI") return 1000000;
    else return 500000;
}
function montantMin(codePays="CM") {
    return 500;
}

const piecesIdentites = [
    "CNI",
    "Recépissé CNI",
    "Passport",
    "Carte de séjour",
    "Carte d'étudiant",
    "Carte d'identité scolaire",
    "Carte d'autorisation temporaire",
    "Autre"
];

const typesCartes = [
    {name: "visa", label: "Visa"},
    {name: "mastercard", label: "MasterCard"},
    {name: "amex", label: "American Express"},
    {name: "discover", label: "Discover"},
    {name: "jcb", label: "Japan Credit Bureau"}
];

const modesPaiements = [
    "Bank",
    "Visa",
    "MasterCard",
    "Amex",
    "Discover",
    "JCB",
    "Orange Money Cameroun",
    "MTN Mobile Money Cameroun",
    "Express Union Mobile Money",
    "YUP",
    "Orange Money Côte d'Ivoire",
    "Orange Money Guinée",
    "MTN Mobile Money Guinée",
    "TIGO Money",
    "MALITEL MobiCash",
    "AIRTEL Money",
    "WIZALL Money",
    "MOOV Money",
    "ADVANS"
];

const modesPaiementMobile = [
    { name: "Orange Money Cameroun", operateur: "ORANGE", devise: "XAF", pays: "CM" },
    { name: "MTN Mobile Money Cameroun", operateur: "MTN", devise: "XAF", pays: "CM" },
    { name: "Express Union Mobile Money", operateur: "EU", devise: "XAF", pays: "CM" },
    { name: "YUP Cameroun", operateur: "YUP", devise: "XAF", pays: "CM" },
    { name: "NEXTTEL Cameroun", operateur: "NEXTELL", devise: "XAF", pays: "CM", forAirtime: true },
    { name: "YOOMEE Cameroun", operateur: "YOOMEE", devise: "XAF", pays: "CM", forAirtime: true },
    { name: "Orange Money Côte d'Ivoire", operateur: "OM-CI", devise: "XOF", pays: "CI" },
    { name: "MTN Mobile Money Côte d'Ivoire", operateur: "MTN-CI", devise: "XOF", pays: "CI" },
    { name: "MOOV Money", operateur: "MOOV", devise: "XOF", pays: "CI" },
    { name: "TIGO Money", operateur: "TIGO", devise: "XOF", pays: "CI" },
    { name: "YUP Côte d'Ivoire", operateur: "YUP-CI", devise: "XOF", pays: "CI" },
    { name: "ADVANS Côte d'Ivoire", operateur: "ADVANS", devise: "XOF", pays: "CI" },
    { name: "WIZALL Money", operateur: "WIZALL-WALLET", devise: "XOF", pays: "CI" },
    { name: "Orange Money Guinée", operateur: "OM_GUINEE", devise: "GNF", pays: "GN" },
    { name: "MTN Mobile Money Guinée", operateur: "MTN_GUINEE", devise: "GNF", pays: "GN" },
    { name: "MALITEL MobiCash", operateur: "MALITEL", devise: "XOF", pays: "MA" },
    { name: "AIRTEL Money", operateur: "AIRTEL", devise: "KES", pays: "KN" },
    { name: "SAFARICOM", operateur: "SAFARICOM", devise: "KES", pays: "KN" }
];

const operateurWithAirtime = [
    "ORANGE",
    "MTN",
    "NEXTELL",
    "YOOMEE",
    "OM-CI",
    "MTN-CI",
    "OM_GUINEE",
    "MTN_GUINEE",
    "TIGO",
    "MALITEL",
    "AIRTEL",
    "WIZALL-WALLET",
    "MOOV"
];
const operateurOnlyAirtime = ["NEXTELL", "YOOMEE"];

const operateurCM = ["ORANGE", "MTN", "YUP", "EU", "NEXTTEL", "YOOMEE"];
const operateurCI = ["OM-CI", "MTN-CI", "MOOV", "YUP-CI"];
const operateurGN = ["OM_GUINEE", "MTN_GUINEE"];
const operateurMALI = ["MALITEL"];
const operateurKN = ["AIRTEL", "SAFARICOM"];

const operateurXAF = operateurCM;
const operateurXOF = operateurCI.concat(operateurMALI);
const operateurGNF = operateurGN;
const operateurKES = operateurKN;

const operateurAfrique = operateurCM.concat(operateurCI,operateurGN,operateurMALI,operateurKN);
const operateurEurope = [];
const operateurAmerique = [];
const operateurAsie = [];
const operateurAntartique = [];
const operateurOceanie = [];

function getCurrencyMomoVersMomo(operateur) {
    var mode = modesPaiementMobile.filter(value => value.operateur==operateur);
    return mode[0].devise;
}
function getTauxConversionMomoVersMomo(operateurSource, operateurDestination) {
    var modeSource = modesPaiementMobile.filter(value => value.operateur==operateurSource);
    var modeDestination = modesPaiementMobile.filter(value => value.operateur==operateurDestination);
    var deviseSource = devisesAcceptes.filter(value => value.nom==modeSource[0].devise);
    var deviseDestination = devisesAcceptes.filter(value => value.nom==modeDestination[0].devise);
    return deviseSource[0].valeur/((deviseDestination[0].reel)?deviseDestination[0].reel:1);
}
function getPourcentageMomoVersMomo(operateurSource, operateurDestination) {
    var modeSource = modesPaiementMobile.filter(value => value.operateur==operateurSource);
    var modeDestination = modesPaiementMobile.filter(value => value.operateur==operateurDestination);
    var result = 0;
    if (modeSource[0].pays!=modeDestination[0].pays) result += fraisCrossOverPays;
    if (modeSource[0].devise!=modeDestination[0].devise) result += fraisCrossOverDevise;
    if (!isSameContinantMomoVersMomo(operateurSource,operateurDestination)) result += fraisCrossOverContinant;
    return result;
}
function isSameCountryMomoVersMomo(operateurSource, operateurDestination) {
    var modeSource = modesPaiementMobile.filter(value => value.operateur==operateurSource);
    var modeDestination = modesPaiementMobile.filter(value => value.operateur==operateurDestination);
    return modeSource[0].pays==modeDestination[0].pays;
}
function isSameCurrencyMomoVersMomo(operateurSource, operateurDestination) {
    var modeSource = modesPaiementMobile.filter(value => value.operateur==operateurSource);
    var modeDestination = modesPaiementMobile.filter(value => value.operateur==operateurDestination);
    return modeSource[0].devise==modeDestination[0].devise;
}
function isSameContinantMomoVersMomo(operateurSource, operateurDestination) {
    return !(
        (operateurAfrique.indexOf(operateurSource)>=0 && operateurAfrique.indexOf(operateurDestination)===-1) ||
        (operateurAmerique.indexOf(operateurSource)>=0 && operateurAmerique.indexOf(operateurDestination)===-1) ||
        (operateurEurope.indexOf(operateurSource)>=0 && operateurEurope.indexOf(operateurDestination)===-1) ||
        (operateurAsie.indexOf(operateurSource)>=0 && operateurAsie.indexOf(operateurDestination)===-1) ||
        (operateurOceanie.indexOf(operateurSource)>=0 && operateurOceanie.indexOf(operateurDestination)===-1) ||
        (operateurAntartique.indexOf(operateurSource)>=0 && operateurAntartique.indexOf(operateurDestination)===-1)
    );
}


// Frais de transfert de vers un autre
const fraisCrossOverPays = 1.5;
const fraisCrossOverDevise = 0.5;
const fraisCrossOverContinant = 1;


const apiBaseURL = "https://glpay.legrandsoft.com/api"; // "http://localhost:5000/api";
const fApiKey = "FLWPUBK-42e2d3b342bdc35263aa212143fda4b4-X"; //"FLWPUBK_TEST-015007e30f9cca38c2b04f53dc68efbe-X", //
const fApiSecret = "FLWSECK-a72b88d5213a7fee6ce620b18c64cae5-X"; //"FLWPUBK_TEST-015007e30f9cca38c2b04f53dc68efbe-X", //
const paypalClientID = "AWmuzMq-HiUhLcXjVxATRmx1Xi9IGXsusITWknH1wI21cMGUWEWoo8ycBhjiDQV1zFwzFAlUXWyBCY_z";


export {
    routes, 
    devisesAcceptes,
    operateurWithAirtime,
    operateurOnlyAirtime,
    operateurCM,
    operateurCI,
    operateurGN,
    operateurMALI,
    operateurKN,
    operateurXAF,
    operateurXOF,
    operateurGNF,
    operateurKES,
    operateurAfrique,
    operateurEurope,
    operateurAmerique,
    operateurAsie,
    operateurAntartique,
    operateurOceanie,
    typesCartes,
    fraisCrossOverPays,
    fraisCrossOverDevise,
    fraisCrossOverContinant,
    piecesIdentites, 
    modesPaiements, 
    modesPaiementMobile, 
    apiBaseURL, 
    fApiKey,
    fApiSecret,
    paypalClientID,
    TAUX_BANK,
    ENV,
    montantDansMinMax,
    montantMax,
    montantMin,
    getCurrencyMomoVersMomo,
    getTauxConversionMomoVersMomo,
    getPourcentageMomoVersMomo,
    isSameCountryMomoVersMomo,
    isSameCurrencyMomoVersMomo,
    isSameContinantMomoVersMomo
};